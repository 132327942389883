import messagesEn from './messages_en'
import messagesFr from './messages_fr'
import { updateIntl } from 'react-intl-redux'

export const messages = {
	en: messagesEn,
	fr: messagesFr
}

export const changeLanguage = (locale = 'fr') => dispatch => dispatch(updateIntl({
	locale: locale,
	messages: messages[locale]
}))