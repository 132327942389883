import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { Button, infoShape, injectInfos, Input, isEmailOk, ResponsiveForm } from '@oceane/ui'
import { push } from 'connected-react-router'
import { getStyles } from 'isotope-client'
import { injectFormSnackbar } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { genererMotDePasse } from '../services/motDePasseActions'


const styles = (theme) => getStyles({
	generateButton: {
		marginLeft: 10
	},
	welcomeMessage: {
		marginTop: 20,
		marginBottom: 20
	}
})

const validate = (values) => {
	const errors = {}
	if (!values.identifiant) {
		errors.identifiant = { id: 'global.erreurs.obligatoire' }
	} else if (!isEmailOk(values.identifiant)) {
		errors.identifiant = { id: 'global.erreurs.formatEmail' }
	}
	return errors
}


const GenererMotDePasseForm = ({ classes, submitting, infos: { version }, intl, handleSubmit, pristine, genererMotDePasse, valid, dispatch }) => (
	<ResponsiveForm>
		<Typography variant="h5" component="h1">
			<FormattedMessage id={'form.motDePasse.generer'} />
		</Typography>
		{version && <Typography variant="body1" className={classes.welcomeMessage}>
			Oceane V{version}
		</Typography>}
		<Field
			component={Input}
			label={intl.formatMessage({ id: 'form.motDePasse.champs.identifiant' })}
			name="identifiant"
			fullWidth
		/>
		<Grid container justify="flex-end">
			<Button color="primary" onClick={() => dispatch(push('/login'))}>
				<FormattedMessage id='global.btn.retour' />
			</Button>
			<Button
				onClick={handleSubmit(genererMotDePasse)}
				color="primary"
				variant="contained"
				loading={submitting}
				disabled={pristine || !valid}
				className={classes.generateButton}
			>
				<FormattedMessage id='global.btn.valider' />
			</Button>
		</Grid>
	</ResponsiveForm>
)


GenererMotDePasseForm.propTypes = {
	classes: PropTypes.object,
	intl: intlShape,
	infos: infoShape,
	handleSubmit: PropTypes.func,
	pristine: PropTypes.bool,
	valid: PropTypes.bool
}

const actions = {
	genererMotDePasse
}

export default compose(
	injectIntl,
	injectInfos,
	connect(undefined, actions),
	reduxForm({
		form: 'genererMotDePasse',
		validate
	}),
	injectFormSnackbar({ success: { id: 'form.motDePasse.genererSucces' } }),
	withStyles(styles)
)(GenererMotDePasseForm)
