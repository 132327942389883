import * as api from './examensApi'

export const GET_MES_EXAMENS_REQUEST = 'GET_MES_EXAMENS_REQUEST'
export const GET_MES_EXAMENS_SUCCESS = 'GET_MES_EXAMENS_SUCCESS'
export const GET_MES_EXAMENS_ERROR = 'GET_MES_EXAMENS_ERROR'

export const getMesExamens = () => dispatch => {
	dispatch({ type: GET_MES_EXAMENS_REQUEST })

	return api.getMesExamens()
		.then(examens => {
			dispatch({
				type: GET_MES_EXAMENS_SUCCESS,
				examens
			})
			return examens
		})
		.catch(e =>
			dispatch({
				type: GET_MES_EXAMENS_ERROR,
				e
			})
		)
}

export const OPEN_POPUP = 'NOUVEL_EXAMEN/OPEN_POPUP'
export const openPopup = () => dispatch => dispatch({
	type: OPEN_POPUP
})

export const CLOSE_POPUP = 'NOUVEL_EXAMEN/CLOSE_POPUP'
export const closePopup = () => dispatch => dispatch({
	type: CLOSE_POPUP
})

export const POST_EXAMEN_REQUEST = 'POST_EXAMEN_REQUEST'
export const POST_EXAMEN_SUCCESS = 'POST_EXAMEN_SUCCESS'
export const POST_EXAMEN_ERROR = 'POST_EXAMEN_ERROR'

export const postExamen = (values) => dispatch => {
	dispatch({ type: POST_EXAMEN_REQUEST })

	return api.postExamen(values)
		.then(examenId => {
			dispatch({
				type: POST_EXAMEN_SUCCESS,
				examenId
			})
			return examenId
		})
		.catch(e => {
			dispatch({
				type: POST_EXAMEN_ERROR,
				e
			})
			throw e
		})
}
