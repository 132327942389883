import React from 'react'
import { hot } from 'react-hot-loader'
import { Route, Switch } from 'react-router-dom'
import LoginPage from '../modules/login/OceaneLoginPage'
import GenererMotDePassePage from '../modules/motDePasse/generer/GenererMotDePassePage'
import RenouvelerMotDePassePage from '../modules/motDePasse/renouveler/RenouvelerMotDePassePage'
import App from './App'
import MettreAJourEmailPage from '../modules/updateEmail/MettreAJourEmailPage'
import ControleCatsPage from '../modules/controleCats/ControleCatsPage'

const Root = () => (
	<Switch>
		<Route path="/login" component={LoginPage} />
		<Route path="/logout" component={LoginPage} />
		<Route path="/renouvelerMotDePasse" component={RenouvelerMotDePassePage} />
		<Route path="/mettreAJourEmail" component={MettreAJourEmailPage}/>
		<Route path="/genererMotDePasse" component={GenererMotDePassePage} />
		<Route path="/controleCATS" component={ControleCatsPage} />
		<Route path="/" component={App} />
		<Route path="*" component={App} />
	</Switch>
)

export default process.env.NODE_ENV === 'development' ? hot(module)(Root) : Root
