import { withStyles } from '@material-ui/core'
import { Button, Popup } from '@oceane/ui'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isPristine, isValid, SubmissionError, submit } from 'redux-form'
import EtatCivilForm from './components/EtatCivilForm'
import SelectionExamensForm from './components/SelectionExamensForm'
import CREATION_COMPTE_STEP from './services/CREATION_COMPTE_STEP'
import { clearPopupContent, closePopup, CREATION_COMPTE_FORM_NAME, creerCompte, validerExamens } from './services/creationCompteActions'
import * as selectors from './services/creationCompteSelectors'

const styles = () => getStyles({
	popup: {
		width: 932,
		maxWidth: 'unset'
	}
})

class CreationComptePopup extends Component {

	handleCreerCompte = (values) => {
		const { creerCompte, closePopup } = this.props
		return creerCompte(values)
			.then(closePopup)
			.catch(e => {
				throw new SubmissionError(e)
			})
	}

	render() {
		const {
			open,
			intl,
			clearPopupContent,
			classes,
			valid,
			pristine,
			submitForm,
			validerExamens,
			submitting,
			closePopup,
			step
		} = this.props

		const actions = [
			<Button key="close" color="primary" onClick={closePopup}>
				<FormattedMessage id="global.btn.annuler" />
			</Button>
		]

		if (step === CREATION_COMPTE_STEP.SELECTION_EXAMEN) {
			actions.push(
				<Button
					key="validate"
					color="primary"
					variant="contained"
					disabled={!valid || pristine}
					onClick={submitForm}
				>
					<FormattedMessage id='global.btn.valider' />
				</Button>
			)
		} else {
			actions.push(
				<Button
					key="save"
					color="primary"
					variant="contained"
					disabled={!valid || pristine}
					loading={submitting}
					onClick={submitForm}
				>
					<FormattedMessage id='global.btn.enregistrer' />
				</Button>
			)
		}

		return <Popup
			open={open}
			index={step - 1}
			actions={actions}
			title={intl.formatMessage({ id: 'form.creerCompte.titre' })}
			onExited={clearPopupContent}
			dialogClasses={{ paper: classes.popup }}
			content={[
				<SelectionExamensForm onSubmit={validerExamens} />,
				<EtatCivilForm onSubmit={this.handleCreerCompte} />
			]}
		/>
	}
}


CreationComptePopup.propTypes = {
	classes: PropTypes.object,
	open: PropTypes.bool,
	valid: PropTypes.bool,
	pristine: PropTypes.bool,
	submitting: PropTypes.bool,
	step: PropTypes.number,
	closePopup: PropTypes.func,
	validerExamens: PropTypes.func,
	submitForm: PropTypes.func,
	clearPopupContent: PropTypes.func,
	creerCompte: PropTypes.func,
	intl: intlShape
}


const mapStateToProps = state => ({
	open: selectors.isPopupOpen(state),
	step: selectors.currentStep(state),
	valid: isValid(CREATION_COMPTE_FORM_NAME)(state),
	pristine: isPristine(CREATION_COMPTE_FORM_NAME)(state),
	submtting: isPristine(CREATION_COMPTE_FORM_NAME)(state)
})

const actions = dispatch => ({
	closePopup: () => closePopup()(dispatch),
	validerExamens: () => validerExamens()(dispatch),
	clearPopupContent: () => clearPopupContent()(dispatch),
	submitForm: () => dispatch(submit(CREATION_COMPTE_FORM_NAME)),
	creerCompte: values => creerCompte(values)(dispatch)
})

export default compose(
	injectIntl,
	withStyles(styles),
	connect(mapStateToProps, actions)
)(CreationComptePopup)
