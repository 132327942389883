import Divider from '@material-ui/core/Divider'
import Fab from '@material-ui/core/Fab'

import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive'
import DvrIcon from '@material-ui/icons/Dvr'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic'
import { BandeauInfo, DownloadButton, EXAMEN_CANDIDAT_ETAT, EXAMEN_SPECIFIQUE, FilArianeProfil, injectMonProfil, Loader, TYPE_EXAMEN } from '@oceane/ui'

import { push } from 'connected-react-router'
import { getStyles, injectToolbarData } from 'isotope-client'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedDate, FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getExamensType } from '../donneesExamen/donneesExamenActions'
import NouvelExamenPopup from './components/NouvelExamenPopup'
import { getMesExamens, openPopup } from './services/examensActions'
import * as examensSelectors from './services/examensSelectors'
import { download } from 'isotope-client/components/download/IsotopeLink'

const style = (theme) => getStyles({
	container: {
		marginTop: 16,
		[theme.breakpoints.down('md')]: {
			marginBottom: 90
		}
	},
	row: {
		cursor: 'pointer',
		[theme.breakpoints.down('sm')]: {
			alignItems: 'flex-start',
			margin: 5
		},
		[theme.breakpoints.up('md')]: {
			minHeight: 80
		}
	},
	item: {
		[theme.breakpoints.down('sm')]: {
			marginLeft: 10,
			marginRight: -10
		}
	},
	title: {
		flexGrow: 1,
		fontSize: 14,
		fontWeight: 'bold'
	},
	fab: {
		position: 'fixed',
		right: 20,
		bottom: 20
	}
})

class Examens extends Component {
	componentDidMount() {
		const { etatCivil, getMesExamens } = this.props

		if (!!etatCivil && etatCivil.id) {
			getMesExamens()
		}
	}

	getExamenIcon = (examen) => {
		switch (examen.typeExamen) {
			case TYPE_EXAMEN.STANDARD:
				return <DvrIcon />
			case TYPE_EXAMEN.PRATIQUE:
				return <AirplanemodeActiveIcon />
			default:
				return <HeadsetMicIcon />
		}
	}

	/**
	 * Gère le clic sur un examen
	 * @param examen examen sélectionné
	 */
	handleClickOnExamen = (examen) => {
		const {
			goToExamenPage
		} = this.props
		// On redirige vers la page de l'examen
		goToExamenPage(examen.id)
	}

	render() {
		const {
			etatCivil,
			examens,
			loading,
			intl,
			classes,
			getExamensType,
			openPopup
		} = this.props

		if (loading) {
			return <Loader />
		}

		// RG : si le candidat possède une date de sanction >= date du jour, on
		// n'affiche pas le bloc de création d'un nouvel examen
		const canAddExamen = !etatCivil.dateSanction || (!!etatCivil.dateSanction && moment(etatCivil.dateSanction).isBefore(moment()))

		return (
			<React.Fragment>
				<Grid className={classes.container}>
					{examens.map((examen, index) =>
						<React.Fragment key={`examen-${index}`}>
							<Grid
								container
								alignItems="center"
								justify="center"
								className={classes.row}
								onClick={() => this.handleClickOnExamen(examen)}
							>
								<Grid item xs={2} md={1} className={classes.item}>{this.getExamenIcon(examen)}</Grid>
								<Grid container item xs={10} md={11} justify="space-between">
									<Grid item xs={12} md={2}>
										<Typography variant="h6" className={classes.title}>{examen.examenTypeLibelle}</Typography>
									</Grid>
									<Grid item xs={12} md={3}>
										<Typography>
											<FormattedMessage id={`examens.etat.${examen.etat}`} />
											<FormattedMessage id="examens.le" />
											<FormattedDate
												value={examen.dateEtat}
												year="numeric"
												month="2-digit"
												day="2-digit"
											/>
										</Typography>
									</Grid>
									<Grid item xs={12} md={5}>
										{
											(examen.objectif && examen.typeExamen !== 4) &&
											<Typography><FormattedMessage id={`examens.objectif.${examen.objectif}`} /></Typography>
										}
									</Grid>
									<Grid item xs={12} md={2}>
										{(
											examen.examenTypeExamenSpecifique === EXAMEN_SPECIFIQUE.CATS
											&& examen.etat === EXAMEN_CANDIDAT_ETAT.EDITE
										) && (
											<DownloadButton
												title={<FormattedMessage id="examens.certificat" />}
												onClick={($event) => {
													download(`/files/me/examens/${examen.id}/certificat`)
													$event.stopPropagation()
												}}
											/>
										)}
									</Grid>
								</Grid>
							</Grid>
							<Divider />
						</React.Fragment>
					)}
					<BandeauInfo message={<span
						dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'examens.aide' }) }} />} />
				</Grid>
				{canAddExamen &&
				<Fab
					color="primary"
					aria-label="Add"
					className={classes.fab}
					onClick={() => {
						getExamensType().then(() => openPopup())
					}}
				>
					<AddIcon />
				</Fab>}
				<NouvelExamenPopup />
			</React.Fragment>
		)
	}
}

Examens.propTypes = {
	intl: intlShape,
	etatCivil: PropTypes.object.isRequired,
	examens: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	getMesExamens: PropTypes.func.isRequired,
	goToExamenPage: PropTypes.func.isRequired,
	getExamensType: PropTypes.func.isRequired,
	openPopup: PropTypes.func.isRequired
}


const mapStateToProps = (state) => ({
	examens: examensSelectors.getListeExamens(state),
	loading: examensSelectors.isLoading(state)
})

const actions = {
	getMesExamens,
	getExamensType,
	openPopup,
	goToExamenPage: (idExamen) => dispatch => {
		return dispatch(
			push(`/examens/${idExamen}`)
		)
	}
}

export default compose(
	connect(mapStateToProps, actions),
	injectIntl,
	injectMonProfil,
	injectToolbarData(({ etatCivil }) => ({
		...FilArianeProfil(etatCivil.id, etatCivil.numeroOceane, etatCivil.nomNaissance, etatCivil.prenomPrincipal, false, false),
		title: { id: 'examens.titre' }
	})),
	withStyles(style),
	withTheme()
)(Examens)
