import * as api from './donneesExamenApi'

export const GET_EXAMENS_TYPE_REQUEST = 'GET_EXAMENS_TYPE_REQUEST'
export const GET_EXAMENS_TYPE_SUCCESS = 'GET_EXAMENS_TYPE_SUCCESS'
export const GET_EXAMENS_TYPE_ERROR = 'GET_EXAMENS_TYPE_ERROR'
export const getExamensType = () => dispatch => {
	dispatch({ type: GET_EXAMENS_TYPE_REQUEST })
	return api.getExamensType()
		.then(categories => dispatch({
					type: GET_EXAMENS_TYPE_SUCCESS,
					categories
				})
		)
		.catch(e => {
			dispatch({
				type: GET_EXAMENS_TYPE_ERROR,
				e
			})
			throw e
		})
}
