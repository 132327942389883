import * as api from './motDePasseApi'
import { SubmissionError } from 'redux-form'
import { push } from 'connected-react-router'

export const MODIFIER_MOT_DE_PASSE_REQUEST = 'MODIFIER_MOT_DE_PASSE_REQUEST'
export const MODIFIER_MOT_DE_PASSE_SUCCESS = 'MODIFIER_MOT_DE_PASSE_SUCCESS'
export const MODIFIER_MOT_DE_PASSE_ERROR = 'MODIFIER_MOT_DE_PASSE_ERROR'
export const modifierMotDePasse = (values) => dispatch => {
	dispatch({ type: MODIFIER_MOT_DE_PASSE_REQUEST })
	return api.modifierMotDePasse(values)
		.then(() => dispatch({ type: MODIFIER_MOT_DE_PASSE_SUCCESS }))
		.catch(e => {
			dispatch({ type: MODIFIER_MOT_DE_PASSE_ERROR })
			if (e && e.response && e.response.status === 400) {
				throw new SubmissionError(e)
			} else {
				throw e
			}
		})
}

export const CREER_MOT_DE_PASSE_REQUEST = 'CREER_MOT_DE_PASSE_REQUEST'
export const CREER_MOT_DE_PASSE_SUCCESS = 'CREER_MOT_DE_PASSE_SUCCESS'
export const CREER_MOT_DE_PASSE_ERROR = 'CREER_MOT_DE_PASSE_ERROR'
export const renouvelerMotDePasse = values => dispatch => {
	dispatch({ type: CREER_MOT_DE_PASSE_REQUEST })
	return api.renouvelerMotDePasse(values)
		.then(() => {
			dispatch({ type: CREER_MOT_DE_PASSE_SUCCESS })
			dispatch(push('/login'))
		})
		.catch(e => {
			dispatch({ type: CREER_MOT_DE_PASSE_ERROR })
			if (e && e.response && e.response.status === 400) {
				if (e.bodyError.id === 'form.motDePasse.erreurs.tokenInvalide') {
					dispatch(push('/login'))
				}
				throw new SubmissionError(e)
			} else {
				throw e
			}
		})
}

export const GENERER_MOT_DE_PASSE_REQUEST = 'GENERER_MOT_DE_PASSE_REQUEST'
export const GENERER_MOT_DE_PASSE_SUCCESS = 'GENERER_MOT_DE_PASSE_SUCCESS'
export const GENERER_MOT_DE_PASSE_ERROR = 'GENERER_MOT_DE_PASSE_ERROR'
export const genererMotDePasse = values => dispatch => {
	dispatch({ type: GENERER_MOT_DE_PASSE_REQUEST })
	return api.genererMotDePasse(values)
		.then(() => {
			dispatch({ type: GENERER_MOT_DE_PASSE_SUCCESS })
			dispatch(push('/login'))
		})
		.catch(e => {
			dispatch({ type: GENERER_MOT_DE_PASSE_ERROR })
			if (e && e.response && e.response.status === 400) {
				throw new SubmissionError(e)
			} else {
				throw e
			}
		})
}

export const checkToken = token => dispatch => api.checkToken(token)