import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import { getStyles, Snackbar } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { animated, Spring } from 'react-spring/renderprops'
import { compose } from 'redux'

const styles = theme => getStyles({
	root: {
		backgroundImage: theme.login.background,
		height: '100%',
		width: '100%',
		backgroundSize: 'cover',
		margin: 0,
		overflow: 'hidden'
	},
	page: {
		marginTop: 12,
		marginBottom: 25,
		overflow: 'hidden'
	}
})

const MireConnexionWrapper = ({ children, classes, className: classNameProp }) => {
	document.body.classList.toggle('login-content', true)
	document.body.parentNode.classList.toggle('login-content', true)

	const className = classNames(
		classes.root,
		classNameProp
	)

	return (
		<Grid container alignItems="center" className={className} spacing={16}>
			<Grid item xs={4} lg={7} />
			<Grid item xs={12} lg={4}>
				<Spring native config={{ delay: 500 }} from={{ opacity: 0, left: 1000 }} to={{ opacity: 1, left: 0 }}>
					{props => <animated.div style={{ position: 'relative', ...props }}>
						<Paper>
							<Grid container alignItems="center" justify="center" spacing={16}>
								<Grid item xs={1} />
								<Grid item xs={10} className={classes.page}>
									{children}
								</Grid>
								<Grid item xs={1} />
							</Grid>
						</Paper>
					</animated.div>}
				</Spring>
			</Grid>
			<Grid item xs={1} />
			<Snackbar />
		</Grid>
	)
}

MireConnexionWrapper.propTypes = {
	location: PropTypes.object,
	classes: PropTypes.object.isRequired,
	className: PropTypes.string
}

export default compose(
	withStyles(styles)
)(MireConnexionWrapper)
