import {
	Error404Page,
	Error500Page,
	EtatCivilCandidat,
	handleSession,
	InscriptionPratiquePage,
	InscriptionTheoriquePage,
	Layout,
	OceaneContext,
	OceaneLogo,
	PROFIL_UTILISATEUR,
	refresh as refreshApi,
} from '@oceane/ui'
import { IsotopeContext, Page, refreshToken as refreshTokenAction, storeLocalUser as storeLocalUserApi, ToolbarContext } from 'isotope-client'
import { logOut } from 'isotope-client/modules/login/services/loginActions'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { compose } from 'redux'
import LienManuel from '../layouts/toolbar/components/LienManuel'
import ProfileMenu from '../layouts/toolbar/components/ProfileMenu'
import Examens from '../modules/examens/Examens'
import Inscriptions from '../modules/inscriptions/Inscriptions'
import MotDePassePage from '../modules/motDePasse/modifier/MotDePassePage'
import PiecesJustificativesExamenCandidat from '../modules/piecesJustificatives/components/PiecesJustificativesExamenCandidat'

class App extends Component {
	componentDidMount() {
		const { setProfil } = this.props
		this.refresh()
		this.refreshInterval = setInterval(() => {
			this.refresh()
		}, 1700 * 1000)

		// Set du profil par défaut à candidat
		setProfil(PROFIL_UTILISATEUR.CANDIDAT.id)
	}

	refresh() {
		const { refreshAction, user } = this.props
		if (user && user.token) {
			const token = localStorage.getItem('token')
			refreshApi(token).then((json) => {
				if (json) {
					refreshAction(json.token)
					storeLocalUserApi(json)
				}
			})
		}
	}

	componentWillUnmount() {
		if (this.refreshInterval) {
			clearInterval(this.refreshInterval)
		}
	}

	async checkLogin(location) {
		const { user } = this.props
		if (!user || !user.token) {
			return Promise.resolve(false)
		}
	}

	render() {
		const { location, user } = this.props
		const toolbarElements = [
			<LienManuel key="manuel" />,
			<ProfileMenu key="profil" />
		]
		if (!user.authenticated) {
			return (<Redirect to="/login" />)
		} else {
			return (
				<IsotopeContext.Provider value={{ appName: 'Oceane', logo: OceaneLogo }}>
					<ToolbarContext.Provider value={{ children: toolbarElements }}>
						<OceaneContext.Provider value={{ application: 'oceane-prt-candidat', isPortailCandidat: true }}>
							<Page menu="prt-candidat" location={location}>
								<Layout>
									<Switch>
										<Route exact path="/" render={() => <Redirect to="etat-civil" />} />
										<Route exact path="/motDePasse" component={MotDePassePage} />
										<Route path="/etat-civil" component={EtatCivilCandidat} />
										<Route path="/examens/:idExamen/nouvelle-inscription-pratique-:typePassage" component={InscriptionPratiquePage} />
										<Route path="/examens/:idExamen/nouvelle-inscription-:typePassage-niveau6-:niveau6" component={InscriptionTheoriquePage} />
										<Route path="/examens/:idExamen/nouvelle-inscription-:typePassage-:sessionPapier" component={InscriptionTheoriquePage} />
										<Route path="/examens/:idExamen/nouvelle-inscription-:typePassage" component={InscriptionTheoriquePage} />
										<Route path="/examens/:idExamen/pieces-justificatives" component={PiecesJustificativesExamenCandidat} />
										<Route path="/examens/:idExamen" component={Inscriptions} />
										<Route path="/examens" component={Examens} />
										<Route path="/erreur*" component={Error500Page} />
										<Route path="*" component={Error404Page} />
									</Switch>
								</Layout>
							</Page>
						</OceaneContext.Provider>
					</ToolbarContext.Provider>
				</IsotopeContext.Provider>
			)
		}
	}

}

App.propTypes = {
	location: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
	refreshAction: PropTypes.func.isRequired,
	user: PropTypes.object,
	setProfil: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
	user: state.user
})

const mapDispatchToProps = (dispatch) => ({
	refreshAction: (token) => dispatch(refreshTokenAction(token)),
	logout: () => dispatch(logOut())
})


export default compose(
	handleSession,
	connect(mapStateToProps, mapDispatchToProps)
)(App)
