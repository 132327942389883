import { destroy } from 'redux-form'
import * as api from './creationCompteApi'

export const CREATION_COMPTE_FORM_NAME = 'creerCompte'

export const OPEN_POPUP = 'CREATION_COMPTE/OPEN_POPUP'
export const openPopup = () => dispatch => dispatch({
	type: OPEN_POPUP
})

export const CLOSE_POPUP = 'CREATION_COMPTE/CLOSE_POPUP'
export const closePopup = () => dispatch => dispatch({
	type: CLOSE_POPUP
})

export const VALIDER_EXAMENS = 'CREATION_COMPTE/VALIDER_EXAMENS'
export const validerExamens = () => dispatch => dispatch({
	type: VALIDER_EXAMENS
})

export const CREER_COMPTE_REQUEST = 'CREER_COMPTE_REQUEST'
export const CREER_COMPTE_SUCCESS = 'CREER_COMPTE_SUCCESS'
export const CREER_COMPTE_ERROR = 'CREER_COMPTE_ERROR'
export const creerCompte = (values) => dispatch => {
	dispatch({ type: CREER_COMPTE_REQUEST })

	const examens = Object.keys(values.typesExamen)
		.filter(id => values.typesExamen[id])
		.map(id => id.substring(2))

	const data = {
		etatCivil: values.etatCivil,
		examens
	}

	return api.creerCandidat(data)
		.then(() => dispatch({ type: CREER_COMPTE_SUCCESS }))
		.catch(e => {
			dispatch({ type: CREER_COMPTE_ERROR })
			throw e
		})
}

export const CLEAR_POPUP_CONTENT = 'CREATION_COMPTE/CLEAR_POPUP_CONTENT'
export const clearPopupContent = () => dispatch => {
	dispatch(destroy(CREATION_COMPTE_FORM_NAME))
	dispatch({
		type: CLEAR_POPUP_CONTENT
	})
}
