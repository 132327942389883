import { Divider, Fab, Grid, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {
	BandeauInfo,
	DownloadButton,
	Button,
	EXAMEN_CANDIDAT_OBJECTIF,
	examenCandidatShape,
	getExamenListeInscriptions,
	getFilArianeExamen,
	getInscriptionPiecesJustificatives,
	getMesInscriptionsExamen,
	injectMonProfil,
	injectMonSelectedExam,
	INS_ETAT_EXAMEN_CANDIDAT,
	INS_ETAT_INSCRIPTION,
	inscriptionShape,
	ListButton,
	MODE_PASSAGE,
	NouvelleInscriptionPopup,
	openPopupInscription as openPopup,
	PjPopup,
	resetMesInscriptionsExamen,
	TYPE_EXAMEN,
	EXAMEN_SPECIFIQUE,
	EXAMEN_CANDIDAT_STATUT_DOSSIER
} from '@oceane/ui'
import { getStyles, injectToolbarData, Title } from 'isotope-client'
import { download } from 'isotope-client/components/download/IsotopeLink'
import PropTypes, { arrayOf } from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { push } from 'connected-react-router'

const MIN_SCREEN_SIZE = 935
const ITEM_HEIGHT = 48

const PIECES_JUSTIFICATIVES = 1
const LETTRE_RESULTAT = 2
const FICHE_RECAP = 3
const INSCRIPTION = 4

const style = (theme) => getStyles({
	container: {
		marginTop: 16,
		marginLeft: 12,
		marginRight: 12
	},
	row: {
		height: 80
	},
	detail: {
		color: '#666',
		fontSize: 12
	},
	title: {
		flexGrow: 1,
		fontSize: 14,
		fontWeight: 'bold'
	},
	downloadButtonContent: {
		display: 'inline',
		verticalAlign: 'middle'
	},
	downloadButton: {
		cursor: 'pointer'
	},
	fab: {
		position: 'fixed',
		right: 20,
		bottom: 20
	},
	aucuneInscription: {
		marginTop: 20,
		marginLeft: 10,
		fontWeight: 'bold'
	},
	warningKsaContainer: {
		backgroundColor: 'white',
		padding: '10px 10px',
		justifyContent: 'space-between',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 20,
		border: '2px solid red'
	},
	warningKsaTitle: {
		fontWeight: 'bold',
		marginBottom: 10
	},
	pjGrid: {
		marginBottom: '36px'
	},
	pjButton: {
		marginTop: '18px'
	}
})

class Inscriptions extends PureComponent {

	constructor(props) {
		super(props)
		this.state = {
			anchorEl: null,
			inscription: null
		}
	}

	componentDidMount() {
		const { getMesInscriptionsExamen, examenSelectionne } = this.props
		if (!!examenSelectionne && !!examenSelectionne.id) {
			getMesInscriptionsExamen(examenSelectionne.id)
		}
	}

	componentWillUnmount() {
		const { resetMesInscriptionsExamen } = this.props
		resetMesInscriptionsExamen()
	}

	specificColumns = (inscription) => {
		const {
			intl,
			classes,
			examenSelectionne,
			getInscriptionPiecesJustificatives
		} = this.props

		if (examenSelectionne.typeExamen === TYPE_EXAMEN.PRATIQUE) {
			return (
				<React.Fragment>
					<TableCell md={2} className={classes.row}>
						<Typography>{inscription.dateDebutPrevue}</Typography>
					</TableCell>
					<TableCell xs={12} md={2} className={classes.downloadButton} align="center">
						<ListButton
							title={<FormattedMessage id="inscriptions.piecesJustificatives" />}
							onClick={() => getInscriptionPiecesJustificatives({
								idInscription: inscription.id,
								typeExamen: examenSelectionne.examenTypeLibelle,
								etat: examenSelectionne.etat
							}, true)}
						/>
					</TableCell>
					<TableCell xs={12} md={2} className={classes.downloadButton} align="center">
						{
							// On affiche le picto uniquement si le coordonateur a donné son accord
							inscription.diffusionFRI &&
							<DownloadButton
								title={<FormattedMessage id="inscriptions.ficheRecap" />}
								onClick={() => download(`/files/me/inscriptions/${inscription.id}/ficheRecapitulative`)}
							/>
						}
					</TableCell>
					{
						// RG2 : le picto n'est présent que pour les inscriptions dont l'état
						// est "terminée" et examen "reussi" UNIQUEMENT (cad bordereau licence imprimé).
						(inscription.etatInscription === INS_ETAT_INSCRIPTION.TERMINEE &&
							examenSelectionne.etat === INS_ETAT_EXAMEN_CANDIDAT.REUSSI) &&
						<TableCell xs={12} md={2} className={classes.downloadButton} align="center">
							<DownloadButton
								title={<FormattedMessage id="inscriptions.lettreResultat" />}
								onClick={() => download(`/files/me/inscriptions/${inscription.id}/lettreResultat`)}
							/>
						</TableCell>
					}
				</React.Fragment>
			)
		} else if (examenSelectionne.typeExamen === TYPE_EXAMEN.STANDARD) {
			return (
				<React.Fragment>
					<TableCell xs={12} md={6}>
						{inscription.typePassage === MODE_PASSAGE.PAPIER &&
							<Typography>
								{intl.formatMessage(
									{ id: 'inscriptions.sessionPapier' },
									{ libelleSession: inscription.libelleSession }
								)}
							</Typography>
						}
						{inscription.typePassage === MODE_PASSAGE.ECRAN &&
							<Typography>
								{intl.formatMessage(
									{ id: 'inscriptions.passageEcran' },
									{ dateDebut: inscription.dateDebut, dateFin: inscription.dateFin }
								)}
							</Typography>
						}
					</TableCell>
					<TableCell xs={12} md={2} className={classes.downloadButton} align="center">
						{
							// RG6611
							(
								inscription.etatInscription === INS_ETAT_INSCRIPTION.AFFECTEE
								&& (
									examenSelectionne.statutDossier === EXAMEN_CANDIDAT_STATUT_DOSSIER.VALIDE
									|| examenSelectionne.statutDossier === null
									|| examenSelectionne.statutDossier === undefined
								)
							) && (
								<DownloadButton
									title={<FormattedMessage id="inscriptions.inscription" />}
									onClick={() => download(`/files/me/inscriptions/${inscription.id}/ficheInscription`)}
								/>
							)
						}
					</TableCell>
				</React.Fragment>
			)
		} else {
			return (
				<React.Fragment>
					<TableCell xs={12} md={4}>
						<Typography>{inscription.libelleSession}</Typography>
					</TableCell>
					<TableCell xs={12} md={2} className={classes.downloadButton} align="center">
						<DownloadButton
							title={<FormattedMessage id="inscriptions.ficheInscription" />}
							onClick={() => download(`/files/me/inscriptions/${inscription.id}/ficheInscription`)}
						/>
					</TableCell>
					{inscription.etatInscription === INS_ETAT_INSCRIPTION.TERMINEE &&
						<TableCell xs={12} md={2} className={classes.downloadButton} align="center">
							<DownloadButton
								title={<FormattedMessage id="inscriptions.lettreResultat" />}
								onClick={() => download(`/files/me/inscriptions/${inscription.id}/lettreResultat`)}
							/>
						</TableCell>
					}
				</React.Fragment>
			)
		}
	}

	/**
	 * Crée les items du menu présent uniquement sur mobile permettant de choisir l'option de téléchargement/affichage
	 */
	mobileMenuContent = (open) => {
		const { intl, examenSelectionne } = this.props
		const { anchorEl, inscription } = this.state
		let menuItems = []
		if (examenSelectionne.typeExamen === TYPE_EXAMEN.PRATIQUE) {
			menuItems.push(
				<MenuItem key={1} onClick={() => this.handleClose(PIECES_JUSTIFICATIVES, inscription)}>
					{intl.formatMessage({ id: 'inscriptions.piecesJustificatives' })}
				</MenuItem>
			)
			if (inscription.diffusionFRI) {
				menuItems.push(
					<MenuItem key={2} onClick={() => this.handleClose(FICHE_RECAP, inscription)}>
						{intl.formatMessage({ id: 'inscriptions.ficheRecap' })}
					</MenuItem>
				)
			}
			// RG2 : le picto n'est présent que pour les inscriptions dont l'état
			// est "terminée" et examen "reussi" UNIQUEMENT (cad bordereau licence imprimé).
			if (
				inscription.etatInscription === INS_ETAT_INSCRIPTION.TERMINEE &&
				examenSelectionne.etat === INS_ETAT_EXAMEN_CANDIDAT.REUSSI
			) {
				menuItems.push(
					<MenuItem key={3} onClick={() => this.handleClose(LETTRE_RESULTAT, inscription)}>
						{intl.formatMessage({ id: 'inscriptions.lettreResultat' })}
					</MenuItem>
				)
			}
		} else if (examenSelectionne.typeExamen === TYPE_EXAMEN.STANDARD) {
			menuItems =
				[<MenuItem key={1} onClick={() => this.handleClose(INSCRIPTION, inscription)}>
					{intl.formatMessage({ id: 'inscriptions.inscription' })}
				</MenuItem>]
		} else {
			menuItems.push(
				<MenuItem key={1} onClick={() => this.handleClose(INSCRIPTION, inscription)}>
					{intl.formatMessage({ id: 'inscriptions.ficheInscription' })}
				</MenuItem>
			)
			if (inscription.etatInscription === INS_ETAT_INSCRIPTION.TERMINEE) {
				menuItems.push(
					<MenuItem key={2} onClick={() => this.handleClose(LETTRE_RESULTAT, inscription)}>
						{intl.formatMessage({ id: 'inscriptions.lettreResultat' })}
					</MenuItem>
				)
			}
		}
		return (
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={() => this.handleClose(-1)}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: 200
					}
				}}
			>
				{menuItems}
			</Menu>
		)
	}

	/**
	 * Gère le clic sur le menu présent sur chaque inscription de la vue téléphone
	 */
	handleClick = (event, inscription) => {
		this.setState({ anchorEl: event.currentTarget, inscription })
	}


	/**
	 * Gère la fermeture du menu présent sur chaque inscription de la vue téléphone
	 * @param action indique quelle action mettre en oeuvre en plus de la fermeture du menu
	 * @param inscription inscription concernée
	 */
	handleClose = (action, inscription) => {
		const {
			examenSelectionne,
			getInscriptionPiecesJustificatives
		} = this.props
		switch (action) {
			case PIECES_JUSTIFICATIVES:
				getInscriptionPiecesJustificatives({
					idInscription: inscription.id,
					typeExamen: examenSelectionne.examenTypeLibelle,
					etat: examenSelectionne.etat
				}, true)
				break
			case LETTRE_RESULTAT:
				download(`/files/me/inscriptions/${inscription.id}/lettreResultat`)
				break
			case FICHE_RECAP:
				download(`/files/me/inscriptions/${inscription.id}/ficheRecapitulative`)
				break
			case INSCRIPTION:
				download(`/files/me/inscriptions/${inscription.id}/ficheInscription`)
				break
			default:
				break
		}
		// Dans tous les cas, le menu se ferme
		this.setState({ anchorEl: null, inscription: null })
	}

	/**
	 * Gère l'affichage conditionnel du bouton de création de nouvelle inscription
	 */
	canAddInscription = () => {
		const { examenSelectionne, listeInscriptions } = this.props
		// RG : le bloc nouvelle inscription n'est présent que pour les examens
		// à l'état "créé" ou "en cours", dont l'objectif est
		// "obtention de l'examen"
		// et pour lesquelles toutes les inscriptions non exceptionnelles
		// existantes sont "terminées" ou "abandonnées"
		if (
			listeInscriptions
				.filter(ins => !ins.exceptionnelle && ins.etatInscription !== null &&
					ins.etatInscription !== INS_ETAT_INSCRIPTION.TERMINEE &&
					ins.etatInscription !== INS_ETAT_INSCRIPTION.ABANDONNE)
				.length !== 0
		) {
			return false
		}
		return (
			(examenSelectionne.etat === INS_ETAT_EXAMEN_CANDIDAT.CREE || examenSelectionne.etat === INS_ETAT_EXAMEN_CANDIDAT.EN_COURS) &&
			examenSelectionne.objectif === EXAMEN_CANDIDAT_OBJECTIF.OBTENTION_DIPLOME
		)
	}

	displayWarningKsa = () => {
		const { intl, classes } = this.props
		return <Paper elevation={2} className={classes.warningKsaContainer}>
			<Typography variant="body1" className={classes.warningKsaTitle}>
				<FormattedMessage id="inscriptions.warningKsaTitle" />
			</Typography>
			<Typography variant="body1">
				<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'inscriptions.warningKsa' }) }} />
			</Typography>
		</Paper>
	}

	render() {
		const {
			intl,
			classes,
			etatCivil,
			examenSelectionne,
			listeInscriptions,
			location,
			goToPiecesJustificativesPage
		} = this.props

		const { anchorEl } = this.state
		const legende = examenSelectionne.typeExamen === TYPE_EXAMEN.PRATIQUE ? 'inscriptions.aidePratique' : 'inscriptions.aide'
		const open = Boolean(anchorEl)

		return (
			<React.Fragment>
				{(!examenSelectionne.epreuveKSA && examenSelectionne.typeFCL) && this.displayWarningKsa()}
				{location.search === '?payfip' && examenSelectionne.typeExamen === TYPE_EXAMEN.PRATIQUE && <BandeauInfo message={
					<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'inscriptions.payfip' }) }} />
				} />}

				{
					examenSelectionne.examenTypeExamenSpecifique === EXAMEN_SPECIFIQUE.CATS
					&& examenSelectionne.etat === INS_ETAT_EXAMEN_CANDIDAT.EN_COURS
					&& (
						<Grid className={classes.pjGrid}>
							<Title value={<FormattedMessage id="inscriptions.piecesJustificativesDossier.title" />} />

							{
								examenSelectionne.statutDossier && (
									<Typography><FormattedMessage id={`inscriptions.piecesJustificativesDossier.statutDossier.${examenSelectionne.statutDossier}`} /></Typography>
								)
							}

							<Button
								color="primary"
								variant="contained"
								onClick={() => goToPiecesJustificativesPage(examenSelectionne.id)}
								className={classes.pjButton}
							>
								<FormattedMessage id="inscriptions.piecesJustificativesDossier.button" />
							</Button>
						</Grid>
					)
				}

				<Title value={<FormattedMessage id="inscriptions.vosInscriptions.title" />} />

				{listeInscriptions.filter(ins => ins.etatInscription !== null).length === 0 &&
					<Typography className={classes.aucuneInscription}>
						<FormattedMessage id="inscriptions.aucune" />
					</Typography>
				}
				{window.innerWidth >= MIN_SCREEN_SIZE &&
					<Table>
						<TableBody>
							{listeInscriptions.filter(ins => ins.etatInscription !== null).map((inscription, index) =>
								<TableRow
									key={`inscription-${index}`}
									className={classes.row}
								>
									<TableCell md={2} className={classes.row} align="center">
										<Typography
											variant="h6"
											className={classes.title}
										>
											{inscription.numeroInscription}
										</Typography>
									</TableCell>
									<TableCell md={2} className={classes.row} align="center">
										<Typography>
											<FormattedMessage
												id={`inscriptions.etat.${inscription.etatInscription}`} />
										</Typography>
									</TableCell>
									{this.specificColumns(inscription)}
								</TableRow>
							)}
						</TableBody>
					</Table>
				}
				{window.innerWidth < MIN_SCREEN_SIZE &&
					<Grid className={classes.container}>
						{listeInscriptions.filter(ins => ins.etatInscription !== null).map((inscription, index) => {
							return (
								<React.Fragment key={`inscription-${index}`}>
									<Grid
										container
										alignItems="center"
										justify="center"
										className={classes.row}
									>
										<Grid item xs={3}>
											<Typography variant="h6"
														align="center"
														className={classes.title}>{inscription.numeroInscription}
											</Typography>
										</Grid>
										<Grid container item xs={7} justify="space-between">
											<Grid item xs={12}>
												<Typography>
													{intl.formatMessage(
														{ id: 'inscriptions.inscriptionType' },
														{ type: intl.formatMessage({ id: `inscriptions.type.${inscription.typePassage}` }) }
													)}
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Typography className={classes.detail}>
													<FormattedMessage
														id={`inscriptions.etat.${inscription.etatInscription}`} />
												</Typography>
											</Grid>
											<Grid item xs={12}>
												{inscription.typePassage === MODE_PASSAGE.PAPIER &&
													<Typography className={classes.detail}>
														{inscription.libelleSession}
													</Typography>
												}
												{inscription.typePassage === MODE_PASSAGE.ECRAN &&
													<Typography className={classes.detail}>
														{intl.formatMessage(
															{ id: 'inscriptions.passageEcranShort' },
															{ dateDebut: inscription.dateDebut, dateFin: inscription.dateFin }
														)}
													</Typography>
												}
												{inscription.typePassage === MODE_PASSAGE.PRATIQUE &&
													<Typography
														className={classes.detail}>{inscription.dateDebutPrevue}</Typography>
												}
											</Grid>
										</Grid>
										<Grid item xs={2}>
											<IconButton onClick={(e) => this.handleClick(e, inscription)}>
												<MoreVertIcon />
											</IconButton>
										</Grid>
									</Grid>
									<Divider />
								</React.Fragment>
							)
						})}
						{open && this.mobileMenuContent(open)}
					</Grid>
				}

				{examenSelectionne.objectif === EXAMEN_CANDIDAT_OBJECTIF.VALIDATION_EPREUVES &&
					// RG : pour les examens dont l'objectif est la validation d'épreuves,
					// un message indique au candidat
					// les moyens de s'inscrire (MSG107)
					<BandeauInfo message={
						<span dangerouslySetInnerHTML={
							{ __html: intl.formatMessage({ id: 'inscriptions.aideValidationEpreuves' }) }
						} />
					} />}

				<BandeauInfo message={
					<span dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: legende }) }} />
				} />

				<PjPopup
					etatCivil={{
						numeroOceane: etatCivil.numeroOceane,
						prenomPrincipal: etatCivil.prenomPrincipal,
						nomNaissance: etatCivil.nomNaissance
					}}
				/>

				<NouvelleInscriptionPopup
					examenSelectionne={examenSelectionne}
					inscriptionAttentePaiement={listeInscriptions.filter(ins => ins.etatInscription === null && ins.idOp !== null).length !== 0}
				/>

				{this.canAddInscription() && <Fab
					color="primary"
					className={classes.fab}
					onClick={this.handleOpenPopup}
				>
					<AddIcon />
				</Fab>}
			</React.Fragment>
		)
	}

	/**
	 * Si l'examen est pratique, on passera directement à la deuxième étape de la popup
	 */
	handleOpenPopup = () => {
		const { openPopup, examenSelectionne } = this.props
		openPopup(examenSelectionne.typeExamen)
	}
}

const mapStateToProps = (state) => ({
	listeInscriptions: getExamenListeInscriptions(state)
})

const actions = {
	openPopup,
	getMesInscriptionsExamen,
	resetMesInscriptionsExamen,
	getInscriptionPiecesJustificatives,
	goToPiecesJustificativesPage: (idExamen) => dispatch => {
		return dispatch(
			push(`/examens/${idExamen}/pieces-justificatives`)
		)
	}
}

Inscriptions.propTypes = {
	intl: intlShape,
	etatCivil: PropTypes.object,
	getInscriptionPiecesJustificatives: PropTypes.func,
	listeInscriptions: arrayOf(inscriptionShape),
	examenSelectionne: examenCandidatShape,
	getMesInscriptionsExamen: PropTypes.func,
	resetMesInscriptionsExamen: PropTypes.func,
	goToPiecesJustificativesPage: PropTypes.func,
}

export default compose(
	injectIntl,
	injectMonProfil,
	injectMonSelectedExam,
	connect(mapStateToProps, actions),
	injectToolbarData(({ etatCivil, examenSelectionne, location }) => {
		return ({
			...getFilArianeExamen(
				etatCivil.id,
				etatCivil.numeroOceane,
				etatCivil.nomNaissance,
				etatCivil.prenomPrincipal,
				examenSelectionne.examenTypeLibelle,
				examenSelectionne.etat,
				false,
				!(location && location.search === '?payfip')
			),
			title: { id: 'inscriptions.title' }
		})
	}),
	withStyles(style),
	withTheme()
)(Inscriptions)
