import { combineReducers } from 'redux'
import { GET_EXAMENS_TYPE_SUCCESS } from './donneesExamenActions'

const categoriesExamenInit = []
const categoriesExamen = (state = categoriesExamenInit, action) => {
	switch (action.type) {
		case GET_EXAMENS_TYPE_SUCCESS:
			return action.categories
		default:
			return state
	}
}

const typesExamenParCategorieInit = {}
const typesExamenParCategorie = (state = typesExamenParCategorieInit, action) => {
	switch (action.type) {
		case GET_EXAMENS_TYPE_SUCCESS:
			return action.categories.reduce((acc, categorie) => {
				acc[categorie.id] = categorie.examensType
				return acc
			}, {})
		default:
			return state
	}
}

export const donneesExamen =  combineReducers({
	categoriesExamen,
	typesExamenParCategorie
})
