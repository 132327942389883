import { createSelector } from 'reselect'

const getLocalState = state => state.candidat.examens

export const getListeExamens = createSelector(
	getLocalState,
	examens => examens.list
)

export const isLoading = createSelector(
	getLocalState,
	examens => examens.loading
)

const getNouveau = createSelector(
	getLocalState,
	examens => examens.nouveau
)

export const isOpened = createSelector(
	getNouveau,
	nouveau => nouveau.open
)

