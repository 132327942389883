import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import { Button, infoShape, injectInfos, Input, ResponsiveForm } from '@oceane/ui'
import { getStyles } from 'isotope-client'
import { injectFormSnackbar } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { renouvelerMotDePasse } from '../services/motDePasseActions'

const styles = (theme) => getStyles({
	warning: {
		color: theme.palette.primary.main,
		marginBottom: 10
	},
	welcomeMessage: {
		marginTop: 20,
		marginBottom: 20
	}
})

const validate = (values) => {
	const errors = {}
	if (!values.nouveauMotDePasse || /^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*)$/.test(values.nouveauMotDePasse)) {
		errors.nouveauMotDePasse = { id: 'form.motDePasse.erreurs.nouveauMotDePasse' }
	}
	if (values.nouveauMotDePasse !== values.confirmerMotDePasse) {
		errors.confirmerMotDePasse = { id: 'form.motDePasse.erreurs.confirmerMotDePasse' }
	}
	return errors
}

const RenouvelerMotDePasseForm = ({ classes, submitting, infos: { version }, intl, handleSubmit, renouvelerMotDePasse, pristine, valid }) => (
	<ResponsiveForm>
		<Typography variant="h5" component="h1">
			<FormattedMessage id={'form.motDePasse.renouveler'} />
		</Typography>
		{version && <Typography variant="body1" className={classes.welcomeMessage}>
			Oceane V{version}
		</Typography>}
		<Field
			component={Input}
			type="password"
			label={intl.formatMessage({ id: 'form.motDePasse.champs.nouveauMotDePasse' })}
			name="nouveauMotDePasse"
			fullWidth
		/>
		<Field
			component={Input}
			type="password"
			label={intl.formatMessage({ id: 'form.motDePasse.champs.confirmerMotDePasse' })}
			name="confirmerMotDePasse"
			fullWidth
		/>
		<Typography variant="caption" className={classes.warning}>
			{intl.formatMessage({ id: 'form.motDePasse.warning' })}
		</Typography>
		<Grid container justify="flex-end">
			<Button
				onClick={handleSubmit(renouvelerMotDePasse)}
				loading={submitting}
				color="primary"
				variant="contained"
				disabled={pristine || !valid}
			>
				<FormattedMessage id='global.btn.enregistrer' />
			</Button>
		</Grid>
	</ResponsiveForm>
)


RenouvelerMotDePasseForm.propTypes = {
	classes: PropTypes.object,
	intl: intlShape,
	infos: infoShape,
	renouvelerMotDePasse: PropTypes.func,
	handleSubmit: PropTypes.func,
	pristine: PropTypes.bool,
	valid: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => ({
	initialValues: {
		token: ownProps.token
	}
})

const actions = {
	renouvelerMotDePasse
}

export default compose(
	injectIntl,
	injectInfos,
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'renouvelerMotDePasse',
		validate
	}),
	injectFormSnackbar({ success: { id: 'form.motDePasse.succes' } }),
	withStyles(styles)
)(RenouvelerMotDePasseForm)
