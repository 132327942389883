import { combineReducers } from 'redux'

import * as actions from './examensActions'

const list = (state = [], action) => {
	switch (action.type) {
		case actions.GET_MES_EXAMENS_SUCCESS:
			return action.examens
		case actions.GET_MES_EXAMENS_ERROR:
			return []
		default:
			return state
	}
}

const loading = (state = false, action) => {
	switch (action.type) {
		case actions.GET_MES_EXAMENS_REQUEST:
			return true
		case actions.GET_MES_EXAMENS_SUCCESS:
		case actions.GET_MES_EXAMENS_ERROR:
			return false
		default:
			return state
	}
}

const initialNouveau = {
	open: false
}

const nouveau = (state = initialNouveau, action) => {
	switch (action.type) {
		case actions.OPEN_POPUP:
			return {
				open: true
			}
		case actions.CLOSE_POPUP:
			return initialNouveau
		default:
			return state
	}
}

export const examens = combineReducers({
	list,
	loading,
	nouveau,
})
