import { createSelector } from 'reselect'

const getLocalState = state => state.donneesExamen

export const getCategories = createSelector(
	getLocalState,
	state => state.categoriesExamen
)

export const getTypesExamenParCategorie = createSelector(
	getLocalState,
	state => state.typesExamenParCategorie
)
