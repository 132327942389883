import green from '@material-ui/core/colors/green'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import {
	Button,
	fetchUser as fetchUserApi,
	infoShape,
	injectInfos,
	Input,
	InputRadio,
	Link as LinkRouter,
	Loader,
	login as loginApi,
	Popup
} from '@oceane/ui'
import { push } from 'connected-react-router'
import { getStyles, Link } from 'isotope-client'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { getUser as getUserAction, logUser as logUserAction } from 'isotope-client/modules/login/services/loginActions'
import { storeLocalUser as storeLocalUserApi } from 'isotope-client/modules/login/services/loginApi'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { IntlProvider } from 'react-intl-redux'
import { connect } from 'react-redux'
import { animated, Trail } from 'react-spring/renderprops'
import { compose } from 'redux'
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form'
import { changeLanguage, messages } from '../../config/intlActions'
import { openPopup } from '../creerCompte/services/creationCompteActions'
import * as creationCompteSelectors from '../creerCompte/services/creationCompteSelectors'
import { getExamensType } from '../donneesExamen/donneesExamenActions'
import * as donneesExamenSelectors from '../donneesExamen/donneesExamenSelectors'
import * as userSelectors from './userSelectors'

const validate = values => {
	const errors = {}
	if (!values.login) {
		errors.login = 'L\'identifiant est obligatoire'
	}
	if (!values.password) {
		errors.password = 'Le mot de passe est obligatoire'
	}
	return errors
}

const styles = theme => getStyles({
	loginButton: {
		marginLeft: 10,
		[theme.breakpoints.down('md')]: {
			marginBottom: 10
		}
	},
	welcomeMessage: {
		marginTop: 20,
		marginBottom: 20
	},
	mdpOublie: {
		color: theme.palette.primary.main,
		whiteSpace: 'nowrap',
		marginTop: 30
	},
	mail: {
		color: theme.palette.primary.main,
		marginBottom: 16
	},
	linkContainer: {
		display: 'inline-block',
		paddingLeft: '3px'
	},
	link: {
		color: theme.palette.primary.main
	},
	creationCompte: {
		backgroundColor: 'white',
		padding: '10px 10px',
		justifyContent: 'space-between',
		display: 'flex',
		flexDirection: 'column',
		marginTop: 20,
		marginBottom: 20,
		border: '2px solid',
		borderColor: green[600]
	},
	radioLabel: {
		height: 33
	},
	information: {
		backgroundColor: 'white',
		padding: '10px 10px',
		justifyContent: 'space-between',
		display: 'flex',
		flexDirection: 'column',
		border: '2px solid #6361a9',
		marginTop: 20
	},
	informationBloquant: {
		border: '2px solid red',
		marginBottom: 20
	}
})

const localeRadioProps = [{
	value: 'fr',
	label: <img src={`${process.env.PUBLIC_URL}/img/flag_fr.png`} width="33" height="auto" alt="Français" />
}, {
	value: 'en',
	label: <img src={`${process.env.PUBLIC_URL}/img/flag_en.png`} width="33" height="auto" alt="English" />
}]

const defaultLocale = 'fr'

const LoginForm = ({
	                   handleSubmit,
	                   dispatch,
	                   submitting, classes,
	                   infos: { version, mailGestionnaireLogistique, informationsCan },
	                   getInfos,
	                   locale,
	                   showMessageCreationCompteSuccess,
	                   intl,
	                   nextPathname,
	                   categories, getExamensType, openPopup, snackError
                   }) => {

	const [prevLocale, setPrevLocale] = React.useState(locale)
	const [open, setOpen] = React.useState(false)
	const [actions, setActions] = React.useState(undefined)

	const handleOpenPopupCreationCompte = () => {
		let promise
		if (categories.length === 0) {
			promise = getExamensType()
		} else {
			promise = Promise.resolve()
		}
		return promise
			.then(openPopup)
			.catch(() => {
				snackError()
			})
	}

	const loginSubmit = (data, dispatch) => {
		return new Promise((resolve, reject) => loginApi(data)
			.then((json) => {
				if (json) {
					dispatch(logUserAction(json.token))
					storeLocalUserApi(json)
					resolve(json.token)
				} else {
					reject({
						login: { id: 'global.erreurs.mauvaisIdentifiants' }
					})
				}
			})
			.catch(e => {
				if (e && e.response && (e.response.status === 400 || e.response.status === 401)) {
					if (e.login && e.login.id && e.login.id === 'portail.ferme') {
						getInfos()
					} else {
						reject(e)
					}
				}
				reject({
					login: { id: 'global.erreurs.connexionImpossible' }
				})
			})
		).then((token) => fetchUserApi(token)
		).then((user) => {
			dispatch(getUserAction(user))
		}).then(() => {
			if (nextPathname) {
				dispatch(push(nextPathname))
			} else {
				dispatch(push('/'))
			}
		}).catch(e => {
			throw new SubmissionError(e)
		})
	}

	useEffect(() => {
		changeLanguage(defaultLocale)(dispatch)
	}, [])

	useEffect(() => {
		if (prevLocale !== locale) {
			setPrevLocale( locale)
			changeLanguage(locale)(dispatch)
		}
	}, [locale])

	const items = [
		<React.Fragment>
			<Typography variant="h5" component="h1">
				<FormattedMessage id={'connexion.authentification'} />
			</Typography>
			{version && <Typography variant="body1" className={classes.welcomeMessage}>Oceane V{version}</Typography>}
			{showMessageCreationCompteSuccess && <Paper elevation={2} className={classes.creationCompte}>
				<Typography variant="body1">{intl.formatMessage({ id: 'connexion.creationCompteSucces' })}</Typography>
			</Paper>}
		</React.Fragment>,
		<React.Fragment>
			<Field
				component={Input}
				label={<FormattedMessage id={'connexion.identifiant'} />}
				name="login"
				placeholder="Identifiant de connexion"
				fullWidth
			/>
			<Field
				component={Input}
				type="password"
				label={<FormattedMessage id={'connexion.motDePasse'} />}
				name="password"
				placeholder="Mot de passe"
				fullWidth
			/>
		</React.Fragment>,
		<Field
			component={InputRadio}
			name="locale"
			choices={localeRadioProps}
			labelClasses={classes.radioLabel}
		/>,
		<React.Fragment>
			<Typography variant="caption" className={classes.mdpOublie}>
				<FormattedMessage id="connexion.motDePasseOublie" />
				<LinkRouter className={classes.linkContainer} to="/genererMotDePasse">
					<Typography variant="caption" className={`${classes.linkContainer} ${classes.link}`}>
						<FormattedMessage id="connexion.cliquerIci" />
					</Typography>
				</LinkRouter>
			</Typography>
			<Typography variant="caption" className={classes.mail}>
				<FormattedMessage id="connexion.autreProblemeConnexion" />
				<Link className={classes.linkContainer} href={`mailto:${mailGestionnaireLogistique}`}>
					<Typography variant="caption" className={classes.link}>
						{mailGestionnaireLogistique}
					</Typography>
				</Link>
			</Typography>
			<Grid container wrap="wrap-reverse" justify="flex-end">
				<Button
					disabled={submitting}
					color="primary"
					onClick={handleOpenPopupCreationCompte}
				>
					<FormattedMessage id="connexion.creerCompte" />
				</Button>
				<Button
					type="submit"
					loading={submitting}
					variant="contained"
					color="primary"
					className={classes.loginButton}
				>
					<FormattedMessage id="connexion.meConnecter" />
				</Button>
			</Grid>
		</React.Fragment>
	]

	React.useEffect(() => {
		if (informationsCan && informationsCan.titre) {
			setOpen(true)
			if (!informationsCan.bloquant) {
				setActions([
					<Button key="close" variant="contained" color="primary" onClick={() => setOpen(false)}>
						<FormattedMessage id="global.btn.fermer" />
					</Button>
				])
			} else {
				setActions(undefined)
			}
		}
	}, [informationsCan])

	if (!version) {
		return <Loader minHeight={false} />
	}

	return (
		<IntlProvider locale={locale} messages={messages[locale]} key={locale}>
			<React.Fragment>
				<form onSubmit={handleSubmit(loginSubmit)} autoComplete="off">
					<Trail
						config={{ delay: 600 }}
						native
						items={items}
						keys={items.map((_, i) => i)}
						from={{ transform: 'translate3d(1000px,0,0)' }}
						to={{ transform: 'translate3d(0,0,0)' }}
					>
						{item => props => <animated.div style={props}>{item}</animated.div>}
					</Trail>
				</form>
				{informationsCan && informationsCan.contenu && <Popup
					open={open}
					actions={actions}
					title={informationsCan.titre}
					content={<Paper elevation={2} className={`${classes.information} ${informationsCan.bloquant ? classes.informationBloquant : ''}`}>
						<Typography variant="body1">
							<span style={{ whiteSpace: 'pre-wrap' }}>{informationsCan.contenu}</span>
						</Typography>
					</Paper>}
				/>}
			</React.Fragment>
		</IntlProvider>
	)
}

LoginForm.propTypes = {
	nextPathname: PropTypes.string,
	// redux-form
	submitting: PropTypes.bool,
	handleSubmit: PropTypes.func.isRequired,
	infos: infoShape,
	locale: PropTypes.string.isRequired,
	getExamensType: PropTypes.func,
	openPopup: PropTypes.func,
	snackError: PropTypes.func,
	showMessageCreationCompteSuccess: PropTypes.bool,
	intl: intlShape
}

LoginForm.defaultProps = {
	locale: defaultLocale
}

const mapStateToProps = (state) => ({
	user: userSelectors.getUser(state),
	locale: formValueSelector('loginForm')(state, 'locale'),
	initialValues: {
		locale: defaultLocale
	},
	categories: donneesExamenSelectors.getCategories(state),
	showMessageCreationCompteSuccess: creationCompteSelectors.showMessageCreationCompteSucces(state)
})

const actions = {
	getExamensType,
	openPopup
}

export default compose(
	injectInfos,
	injectIntl,
	injectSnackActions,
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'loginForm',
		validate
	}),
	withStyles(styles)
)(LoginForm)
