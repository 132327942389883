import withStyles from '@material-ui/core/styles/withStyles'
import { getStyles } from 'isotope-client'
import React from 'react'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'

const styles = (theme) => getStyles({
	badge: {
		display: 'flex',
		alignItems: 'center',
		height: 20,
		border: '2px solid',
		borderRadius: 3,
		paddingLeft: 10,
		paddingRight: 10,
		textTransform: 'uppercase',
		fontSize: 10,
		color: '#828282',
		borderColor: '#E4E4E4',
		backgroundColor: '#FFFFFF',
	},
	statusOk: {
		color: '#10B981',
		borderColor: '#10B981',
		backgroundColor: '#D1FAE5',
	},
	statusKo: {
		color: '#F43F5E',
		borderColor: '#F43F5E',
		backgroundColor: '#FEE2E2',
	}
})

/**
 * Petit badge rouge ou vert indiquant le statut d'une PJ examen candidat.
 *
 * @param isPjValidee vrai si la PJ est validée, faux sinon
 */
const PieceJustificativeStatusBadge = (
	{
		isPjValidee,
		classes,
		intl
	}
) => {
	return (
		<div className={[
			classes.badge,
			isPjValidee ? classes.statusOk : classes.statusKo
		].join(' ')}>
			{intl.formatMessage({id: `inscriptions.piecesJustificativesDossier.listePJ.status.${isPjValidee ? 'validee' : 'nonConforme'}`})}
		</div>
	)
}

export default compose(
	injectIntl,
	withStyles(styles)
)(PieceJustificativeStatusBadge)
