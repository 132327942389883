import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import { BandeauInfo, Button, FilArianeProfil, injectMonProfil, Input, ResponsiveForm } from '@oceane/ui'
import { getStyles, injectToolbarData } from 'isotope-client'
import { injectFormSnackbar } from 'isotope-client/components/snackbar/services/snackbarInjector'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, reduxForm } from 'redux-form'
import { modifierMotDePasse } from '../services/motDePasseActions'

const styles = () => getStyles({
	container: {
		marginTop: 30
	}
})

const validate = (values) => {
	const errors = {}
	if (!values.motDePasseActuel) {
		errors.motDePasseActuel = { id: 'form.global.erreurs.obligatoire' }
	}
	if (!values.nouveauMotDePasse || /^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*)$/.test(values.nouveauMotDePasse)) {
		errors.nouveauMotDePasse = { id: 'form.motDePasse.erreurs.nouveauMotDePasse' }
	}
	if (values.nouveauMotDePasse !== values.confirmerMotDePasse) {
		errors.confirmerMotDePasse = { id: 'form.motDePasse.erreurs.confirmerMotDePasse' }
	}
	return errors
}

const motDePassePage = ({ intl, classes, submitting, handleSubmit, modifierMotDePasse, pristine, valid }) => (
	<>
		<BandeauInfo message={<FormattedMessage id="form.motDePasse.warning" />} />
		<ResponsiveForm className={classes.container}>
			<Field
				component={Input}
				type="password"
				label={intl.formatMessage({ id: 'form.motDePasse.champs.motDePasseActuel' })}
				name="motDePasseActuel"
				fullWidth
			/>
			<Field
				component={Input}
				type="password"
				label={intl.formatMessage({ id: 'form.motDePasse.champs.nouveauMotDePasse' })}
				name="nouveauMotDePasse"
				fullWidth
			/>
			<Field
				component={Input}
				type="password"
				label={intl.formatMessage({ id: 'form.motDePasse.champs.confirmerMotDePasse' })}
				name="confirmerMotDePasse"
				fullWidth
			/>
			<Grid container justify="flex-end">
				<Button
					color="primary"
					variant="contained"
					onClick={handleSubmit(modifierMotDePasse)}
					loading={submitting}
					disabled={pristine || !valid}
				>
					<FormattedMessage id='global.btn.enregistrer' />
				</Button>
			</Grid>
		</ResponsiveForm>
	</>
)


motDePassePage.propTypes = {
	classes: PropTypes.object,
	intl: intlShape,
	modifierMotDePasse: PropTypes.func,
	snackSuccess: PropTypes.func,
	snackError: PropTypes.func,
	handleSubmit: PropTypes.func,
	pristine: PropTypes.bool,
	valid: PropTypes.bool
}

const mapStateToProps = state => ({
	initialValues: {
		idCandidat: state.user.id
	}
})

const actions = {
	modifierMotDePasse
}

export default compose(
	injectIntl,
	connect(mapStateToProps, actions),
	reduxForm({
		form: 'modifierMotDePasse',
		validate
	}),
	injectMonProfil,
	injectToolbarData(({ etatCivil }) => ({
		...FilArianeProfil(etatCivil.id, etatCivil.numeroOceane, etatCivil.nomNaissance, etatCivil.prenomPrincipal, false, false),
		title: { id: 'menu.motDePasse' }
	})),
	injectFormSnackbar({ success: { id: 'form.motDePasse.succes' } }),
	withStyles(styles)
)(motDePassePage)
