import { combineReducers } from 'redux'
import { LOGIN_SUCCESS } from 'isotope-client'
import {
	CLEAR_POPUP_CONTENT,
	CLOSE_POPUP, CREER_COMPTE_SUCCESS,
	OPEN_POPUP,
	VALIDER_EXAMENS
} from './creationCompteActions'
import CREATION_COMPTE_STEP from './CREATION_COMPTE_STEP'

const open = (state = false, action) => {
	switch (action.type) {
		case OPEN_POPUP:
			return true
		case CLOSE_POPUP:
			return false
		default:
			return state
	}
}

const step = (state = null, action) => {
	switch (action.type) {
		case OPEN_POPUP:
			return CREATION_COMPTE_STEP.SELECTION_EXAMEN
		case VALIDER_EXAMENS:
			return CREATION_COMPTE_STEP.ETAT_CIVIL
		case CLEAR_POPUP_CONTENT:
			return null
		default:
			return state
	}
}

const creationCompteSuccess = (state = false, action) => {
	switch (action.type) {
		case CREER_COMPTE_SUCCESS:
			return true
		case LOGIN_SUCCESS:
			return false
		default:
			return state
	}
}

export const creerCompte =  combineReducers({
	popup: combineReducers({
		open,
		step
	}),
	creationCompteSuccess
})
