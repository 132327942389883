import { fetchFactory } from 'isotope-client'

export const modifierMotDePasse = values => fetchFactory('/candidats/motDePasse', {
	method: 'post',
	body: JSON.stringify(values)
})

export const renouvelerMotDePasse = (values) => fetchFactory('/candidats/motDePasse', {
	method: 'put',
	body: JSON.stringify(values)
}, '/api')

export const genererMotDePasse = (values) => fetchFactory('/candidats/motDePasse/generer', {
	method: 'post',
	body: JSON.stringify(values)
}, '/api')


export const checkToken = token => fetchFactory(`/candidats/checkToken?token=${token}`, {}, '/api')