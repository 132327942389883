import React from 'react'
import { checkEmailValidationToken } from './services/actions'
import { connect } from 'react-redux'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import qs from 'querystring'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'

class MettreAJourEmailPage extends React.Component {

    componentDidMount() {
        const { token, checkToken, snackError, snackSuccess } = this.props
        checkToken(token)
            .then(() => {
                console.log("success")
                snackSuccess({ id: 'updateEmail.success' })
            })
            .catch((e) => {
                console.log("error", e)
                snackError({ id: 'updateEmail.error' })
            })
    }

    render() {
        return <Redirect to='/login'/>
    }

}

const mapStateToProps = (state, ownProps) => ({
    token: qs.parse(ownProps.location.search.substring(1)).token
})

const actions = dispatch => ({
    checkToken: token => checkEmailValidationToken(token)(dispatch)
})

export default compose(
    connect(mapStateToProps, actions),
    injectSnackActions
)(MettreAJourEmailPage)