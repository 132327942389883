import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { BandeauInfo, Select } from '@oceane/ui'
import { FormCheckbox } from 'isotope-client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, FormSection, formValueSelector, reduxForm } from 'redux-form'
import * as selectors from '../../donneesExamen/donneesExamenSelectors'
import { CREATION_COMPTE_FORM_NAME } from '../services/creationCompteActions'

const validate = values => {
	const errors = {}
	if (!values.categorie) {
		errors.categorie = { id: 'global.erreurs.obligatoire' }
	}
	if (!values.typesExamen || Object.values(values.typesExamen).every(value => !value)) {
		errors._error = { id: 'global.erreurs.obligatoire' }
	}
	return errors
}

class SelectionExamensForm extends Component {

	render() {
		const { change, handleSubmit, categories, typesExamen, intl } = this.props
		return <form onSubmit={handleSubmit}>
			<BandeauInfo message={intl.formatMessage({ id: 'form.creerCompte.warning.selection' })} />
			<Typography variant="h6">
				<FormattedMessage id="form.creerCompte.selectionCategorie" />
			</Typography>
			<Field
				name="categorie"
				component={Select}
				onChange={() => change('typesExamen', {})}
				label=""
				id="creerCompte-categorie"
			>
				{categories.map((categorie, key) =>
					<MenuItem
						key={key}
						value={categorie.id}
					>
						{categorie.libelle}
					</MenuItem>)}
			</Field>
			{typesExamen && <React.Fragment>
				<Typography variant="h6">
					<FormattedMessage id="form.creerCompte.selectionExamen" />
				</Typography>
				<FormSection name="typesExamen" component="div">
					{typesExamen.map((typeExamen, key) => <Field
						name={`id${typeExamen.id}`}
						label={`${typeExamen.libelle} (${typeExamen.libelleLong})`}
						key={key}
						component={FormCheckbox}
					/>)}
				</FormSection>
			</React.Fragment>}
		</form>
	}
}


SelectionExamensForm.propTypes = {
	classes: PropTypes.object,
	categories: PropTypes.array,
	typesExamen: PropTypes.array,
	intl: intlShape
}

const getFormValues = formValueSelector(CREATION_COMPTE_FORM_NAME)

const mapStateToProps = state => ({
	categories: selectors.getCategories(state),
	typesExamen: selectors.getTypesExamenParCategorie(state)[getFormValues(state, 'categorie')],
	initialValues: {
		categorie: 0,
		typesExamen: {},
		etatCivil: {}
	}
})

export default compose(
	injectIntl,
	connect(mapStateToProps),
	reduxForm({
		form: CREATION_COMPTE_FORM_NAME,
		destroyOnUnmount: false,
		forceUnregisterOnUnmount: true,
		validate
	})
)(SelectionExamensForm)
