import { withStyles } from '@material-ui/core'

import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { compose } from 'redux'
import { getStyles } from 'isotope-client'
import RenouvelerMotDePasseForm from './RenouvelerMotDePasseForm'
import qs from 'querystring'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { checkToken } from '../services/motDePasseActions'
import MireConnexionWrapper from '../../../layouts/MireConnexionWrapper'

const styles = theme => getStyles({
	root: {
		backgroundImage: theme.login.background,
		height: '100%',
		width: '100%',
		backgroundSize: 'cover',
		margin: 0
	},
	page: {
		marginTop: 12,
		marginBottom: 25
	}
})

class MotDePassePage extends React.Component {

	componentDidMount() {
		const { token, goToLogin, checkToken, snackError } = this.props
		checkToken(token)
			.catch(() => {
				goToLogin()
				snackError({ id: 'form.motDePasse.erreurs.tokenInvalide' })
			})
	}

	render() {
		const { token } = this.props

		return (
			<MireConnexionWrapper>
				<RenouvelerMotDePasseForm token={token} />
			</MireConnexionWrapper>
		)
	}
}


MotDePassePage.propTypes = {
	token: PropTypes.string,
	goToLogin: PropTypes.func
}

const mapStateToProps = (state, ownProps) => ({
	token: qs.parse(ownProps.location.search.substring(1)).token
})

const mapDispatchToProps = dispatch => ({
	goToLogin: () => dispatch(push('/login')),
	checkToken: token => checkToken(token)(dispatch)
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles),
	injectSnackActions
)(MotDePassePage)
