import { BandeauInfo, Error, EtatCivilForm as EtatCivilFormSection, validateEtatCivil } from '@oceane/ui'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { FormSection, formValueSelector, reduxForm } from 'redux-form'
import { CREATION_COMPTE_FORM_NAME } from '../services/creationCompteActions'

const validate = values => ({
	etatCivil: validateEtatCivil(values.etatCivil)
})

class EtatCivilForm extends Component {
	render() {
		const { codePostal, error, handleSubmit, intl } = this.props
		return (
			<form onSubmit={handleSubmit}>
				<BandeauInfo message={intl.formatMessage({ id: 'form.creerCompte.warning.etatCivil' })} />
				<Error error={error} isPopinContainer />
				<FormSection name="etatCivil">
					<EtatCivilFormSection
						creerCompte
						codePostal={codePostal}
					/>
				</FormSection>
			</form>
		)
	}
}


EtatCivilForm.propTypes = {
	classes: PropTypes.object,
	codePostal: PropTypes.string,
	error: PropTypes.array,
	intl: intlShape
}

const getFormValues = formValueSelector(CREATION_COMPTE_FORM_NAME)

const mapStateToProps = state => {
	const etatCivil = getFormValues(state, 'etatCivil')
	return {
		codePostal: etatCivil && etatCivil.adresse && etatCivil.adresse.codePostal
	}
}

export default compose(
	injectIntl,
	connect(mapStateToProps),
	reduxForm({
		form: CREATION_COMPTE_FORM_NAME,
		destroyOnUnmount: false,
		forceUnregisterOnUnmount: true,
		validate
	})
)(EtatCivilForm)
