import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { getCatsInfo } from './services/controleCatsApi'
import Grid from '@material-ui/core/Grid'
import { Error, Loader } from '@oceane/ui'
import imgDgac from '../../assets/img/cats/dgac.png'
import imgEasa from '../../assets/img/cats/easa.png'
import imgA2 from '../../assets/img/cats/a2.jpg'
import imgA1A3 from '../../assets/img/cats/a1a3.jpg'
import imgSTS from '../../assets/img/cats/sts.jpg'
import withStyles from '@material-ui/core/styles/withStyles'
import { compose } from 'redux'
import { getStyles, Title } from 'isotope-client'
import { ControleCatsBean } from './model/controleCatsBean'

const styles = (theme: any) => getStyles({
	page: {
		padding: '30px 30px'
	},
	logo: {
		maxHeight: '100px'
	},
	pictoA1: {
		height: '120px'
	},
	label: {
		fontSize: '12px'
	},
	englishLabel: {
		color: '#919191'
	},
	value: {
		fontSize: '20px',
		fontWeight: 'bold'
	}
})

/**
 * Page de vérification de la validité d'un certificat CATS.
 */
const ControleCatsPage: React.FunctionComponent<{ location: any, classes: any }> = ({
	                                                                                    location,
	                                                                                    classes
                                                                                    }) => {
	const [isValid, setValid] = React.useState(false)
	const [catsInfo, setCatsInfo] = React.useState<ControleCatsBean|undefined>(undefined)
	const [isLoading, setLoading] = React.useState(true)

	useEffect(() => {
		const token = new URLSearchParams(location.search).get('token')
		if (!!token) {
			getCatsInfo(token)
				.then((catsInfo) => {
					setValid(true)
					setCatsInfo(catsInfo)
				})
				.catch(() => {
					setValid(false)
				})
				.finally(() => {
					setLoading(false)
				})
		} else {
			setValid(false)
			setLoading(false)
		}

	}, [])

	return (
		<Grid container justify="flex-start" alignItems="center" direction="column" className={classes.page} spacing={24}>
			<Grid item container justify="space-between" alignItems="flex-start">
				<img src={imgEasa} alt="Logo EASA" className={classes.logo} />
				<img src={imgDgac} alt="Logo DGAC" className={classes.logo} />
			</Grid>
			{
				isLoading ?
					<Loader />
					: (
						(isValid && !!catsInfo) ?
							<Grid item container justify="flex-start" alignItems="center" direction="column" spacing={24}>
								<Title value="REMOTE PILOT CERTIFICATE OF THEORETICAL KNOWLEDGE FOR STS" />

								<Grid item container justify="center" alignItems="center">
									<img src={imgA1A3} alt="Logo A1/A3" className={classes.pictoA1} />
									<img src={imgSTS} alt="Logo STS" className={classes.pictoA1} />
									{catsInfo.bapd && <img src={imgA2} alt="Logo A2" className={classes.pictoA1} />}
								</Grid>

								<Grid item container justify="space-between" alignItems="flex-start" direction="row" spacing={24}>
									<Grid item container md={6} sm={12} justify="flex-start" alignItems="flex-start" direction="column">
										<Typography className={classes.label}>Prénom <span className={classes.englishLabel}>(First name)</span></Typography>
										<Typography className={classes.value}>{catsInfo.prenoms}</Typography>
									</Grid>

									<Grid item container md={6} sm={12} justify="flex-start" alignItems="flex-start" direction="column">
										<Typography className={classes.label}>Nom <span className={classes.englishLabel}>(Last name)</span></Typography>
										<Typography className={classes.value}>{catsInfo.nom}</Typography>
									</Grid>

									<Grid item container md={6} sm={12} justify="flex-start" alignItems="flex-start" direction="column">
										<Typography className={classes.label}>Numéro de télépilote <span className={classes.englishLabel}>(Identification number)</span></Typography>
										<Typography className={classes.value}>{catsInfo.noTelepilote}</Typography>
									</Grid>

									<Grid item container md={6} sm={12} justify="flex-start" alignItems="flex-start" direction="column">
										<Typography className={classes.label}>Date d'expiration <span className={classes.englishLabel}>(Expiration date)</span></Typography>
										<Typography className={classes.value}>{catsInfo.dateFinValidite}</Typography>
									</Grid>
								</Grid>
							</Grid>
							:
							<Error error={['Certificat non trouvé.']} />
					)
			}
		</Grid>
	)
}

export default compose(
	withStyles(styles)
)(ControleCatsPage)