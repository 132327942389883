import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { getStyles, injectToolbarData, Title } from 'isotope-client'
import React from 'react'
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Field, FieldArray, FormErrors, formValueSelector, reduxForm } from 'redux-form'
import {
	CandidatNoTelepiloteInfoLightBean,
	CodePaysEasa,
	ExamenCandidatPieceJustificative
} from '../model/PiecesJustificativesExamenCandidatModel'
import { Candidat } from '../../model/candidat'
import { InfoExamenCandidat } from '../../examens/model/examenCandidat'
import { download } from 'isotope-client/components/download/IsotopeLink'
import {
	Actions,
	BandeauInfo,
	Button,
	checkAuthorities,
	checkCandidatHasAlreadyPassedExamenCATS,
	checkMonFichierPJExamenCandidatExists,
	DATE_FORMAT_MMDDYYYY_SLASH,
	DATE_FORMAT_YYYYMMDD_DASH,
	DateInput,
	DATETIME_FORMAT_SERVER_TIMESTAMP,
	deletePieceJustificativeExamenCandidat,
	EmptyResult,
	Error,
	EXAMEN_CANDIDAT_STATUT_DOSSIER,
	formatDate,
	getCodesPaysEasa,
	getExamenListeInscriptions,
	getFilArianeExamen,
	getListePiecesJustificativesExamenSelectionne,
	getMesInscriptionsExamen,
	getMesPiecesJustificativesForExamenCandidat,
	injectMonProfil,
	injectMonSelectedExam,
	Input,
	InputDisabled,
	InputFile,
	InputRadio,
	INS_ETAT_INSCRIPTION,
	Loader,
	MODE_PASSAGE,
	PieceJustificativeNameBadge,
	PROFIL_UTILISATEUR,
	resetMesPiecesJustificativesForExamen,
	ResponsiveForm,
	sendMonDossierPJExamenCandidatForValidation,
	SmallSelect,
	updateMonNoTelepilote,
	uploadPieceJustificativeExamenCandidat
} from '@oceane/ui'
import PieceJustificativeStatusBadge from './PieceJustificativeStatusBadge'
import moment from 'moment'
import { InscriptionBean } from '../../../utils/redux'

const styles = (theme: any): React.CSSProperties => getStyles({
	row: {
		minHeight: 60,
		borderBottom: '1px solid rgba(224, 224, 224, 1)', // Diviseur entre les lignes du "tableau"
		paddingBottom: 12,
		marginBottom: 10
	},
	inputSuffixe: {
		marginTop: 2
	},
	placeholder: {
		color: 'rgba(0, 0, 0, 0.4)'
	},
	// Imite le libellé des champs de formulaire
	fakeFieldLabel: {
		color: theme.palette.primary.main,
		fontWeight: 'bold',
		fontSize: 13
	},
	telepilote: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'start',
		columnGap: 5
	},
	telepiloteRP: {
		paddingTop: 6
	},
	pjBadgeList: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexWrap: 'wrap',
		columnGap: '12px',
		rowGap: '6px',
		marginTop: '10px'
	},
	pjTitleContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		columnGap: 10
	},
	emptyPj: {
		width: '100%'
	},
	marginTop: {
		marginTop: '20px',
	},
	marginBot: {
		marginBottom: '20px',
	},
	dateInput: {
		marginTop: '0'
	}
})

const actions = {
	getMesPiecesJustificativesForExamenCandidatAction: getMesPiecesJustificativesForExamenCandidat,
	resetMesPiecesJustificativesForExamenAction: resetMesPiecesJustificativesForExamen,
	uploadPieceJustificativeExamenCandidatAction: uploadPieceJustificativeExamenCandidat,
	deletePieceJustificativeExamenCandidatAction: deletePieceJustificativeExamenCandidat,
	sendMonDossierPJExamenCandidatForValidationAction: sendMonDossierPJExamenCandidatForValidation,
	getMesInscriptionsExamenAction: getMesInscriptionsExamen,
	updateMonNoTelepiloteAction: updateMonNoTelepilote
} as const

type ActionProps = {
	getMesPiecesJustificativesForExamenCandidatAction: (idExamenCandidat: number) => Promise<void>
	resetMesPiecesJustificativesForExamenAction: () => Promise<void>
	uploadPieceJustificativeExamenCandidatAction: (idExamenCandidat: number,
	                                               idPieceJustificative: number,
	                                               fichier: File) => Promise<number>
	deletePieceJustificativeExamenCandidatAction: (idExamenCandidat: number,
	                                               idPieceJustificative: number,
	                                               idFichierPieceJustificative: number) => Promise<void>
	sendMonDossierPJExamenCandidatForValidationAction: (idExamenCandidat: number, form: FormValues) => Promise<void>
	getMesInscriptionsExamenAction: (idExamenCandidat: number) => Promise<InscriptionBean[]>,
	updateMonNoTelepiloteAction: (noTelepilote: string) => Promise<void>
}

type OwnProps = {
	etatCivil: Candidat
	examenSelectionne: InfoExamenCandidat
	piecesJustificativesExamen: ExamenCandidatPieceJustificative[]
	intl: any
	classes: any
	snackError: any
	snackSuccess: any
	change: any
	submitting: boolean
	handleSubmit: any,
	listeInscriptions: InscriptionBean[],
	invalid: boolean,
	isTitulaireBapd: boolean
}

type PiecesJustificativesExamenCandidatProps = OwnProps & ActionProps

const FORM_NAME = 'piecesJustificativesExamenForm'
const FIELD_NAME_PJ = 'piecesJustificativesExamen'
const FIELD_NAME_TELEPILOTE_PREFIXE = 'numeroTelepilotePrefixe'
const FIELD_NAME_TELEPILOTE_SUFFIXE = 'numeroTelepiloteSuffixe'
const FIELD_NAME_DATE_EXPIRATION_JUSTIFICATIF = 'dateExpirationJustificatif'
const FIELD_NAME_CERTIFICAT_COMPLEMENTAIRE = 'certificatComplementaire'

type FormValues = {
	[FIELD_NAME_TELEPILOTE_PREFIXE]: string,
	[FIELD_NAME_TELEPILOTE_SUFFIXE]: string,
	[FIELD_NAME_DATE_EXPIRATION_JUSTIFICATIF]: string,
	[FIELD_NAME_CERTIFICAT_COMPLEMENTAIRE]: string
}

const PiecesJustificativesExamenCandidat = ({
	                                            examenSelectionne,
	                                            etatCivil,
	                                            piecesJustificativesExamen,
	                                            getMesPiecesJustificativesForExamenCandidatAction,
	                                            resetMesPiecesJustificativesForExamenAction,
	                                            intl,
	                                            classes,
	                                            snackError,
	                                            snackSuccess,
	                                            change,
	                                            submitting,
	                                            handleSubmit,
	                                            uploadPieceJustificativeExamenCandidatAction,
	                                            deletePieceJustificativeExamenCandidatAction,
	                                            sendMonDossierPJExamenCandidatForValidationAction,
	                                            getMesInscriptionsExamenAction,
	                                            updateMonNoTelepiloteAction,
	                                            invalid,
	                                            isTitulaireBapd
                                            }: PiecesJustificativesExamenCandidatProps) => {
	// RG6588/63626
	const [isTelepiloteFieldDisabled, setIsTelepiloteFieldDisabled] = React.useState(false)
	const [isCATSCheckLoading, setCATSCheckLoading] = React.useState(false)
	const [isPJLoading, setPJLoading] = React.useState(false)
	const [codesPaysEasa, setCodesPaysEasa] = React.useState<CodePaysEasa[]>([])
	// On a besoin de cette variable pour stocker temporairement le noTelepilote après que le candidat ait
	// submit le form, car à ce moment-là on affichera en temps normal le noTelepilote du store Redux, sauf que
	// celui-ci nous est transmis via un injector et n'est pas triggered de nouveau même après màj du store
	const [
		tempNoTelepiloteAfterSubmit,
		setTempNoTelepiloteAfterSubmit
	] = React.useState<string | undefined>(undefined)

	/**
	 * Vrai si la balle est dans le camp du candidat (il doit compléter ou corriger son dossier),
	 * faux si elle est dans le camp du gestionnaire (il doit examiner les PJs et valider ou rejeter le dossier)
	 */
	const isActionAttendueCandidat = (
		examenSelectionne.statutDossier === EXAMEN_CANDIDAT_STATUT_DOSSIER.A_COMPLETER
		|| examenSelectionne.statutDossier === EXAMEN_CANDIDAT_STATUT_DOSSIER.NON_CONFORME
	)

	React.useEffect(() => {
		if (isActionAttendueCandidat) {
			setCATSCheckLoading(true)
			checkCandidatHasAlreadyPassedExamenCATS()
				.then((info: CandidatNoTelepiloteInfoLightBean) => {
					// RG6588/63626, RG6591/63626
					if (info.noTelepilote) {
						if (info.hasPastExamenCatsValide) {
							setIsTelepiloteFieldDisabled(true)
						}
					}
				})
				.finally(() => {
					setCATSCheckLoading(false)
				})
		}

	}, [examenSelectionne && examenSelectionne.id])

	React.useEffect(() => {
		if (!!examenSelectionne && !!examenSelectionne.id) {
			setPJLoading(true);
			getMesPiecesJustificativesForExamenCandidatAction(examenSelectionne.id)
				.then(({ payload: piecesJustificatives }: any) => {
					// Insertion des PJ dans le Redux form
					change(FIELD_NAME_PJ, piecesJustificatives.map((pj: any) => ({
						...pj,
						validee: pj.validee
					})))
				})
				.catch(() => {
					change(FIELD_NAME_PJ, [])
					snackError(<FormattedMessage id="global.erreurs.generic" />)
				})
				.finally(() => {
					setPJLoading(false)
				})

			// Récupération de la liste des inscriptions à l'examen candidat
			getMesInscriptionsExamenAction(examenSelectionne.id)
				.catch(() => {
					snackError(<FormattedMessage id="global.erreurs.generic" />)
				})

			// Récupération de la liste des codes pays EASA à insérer dans la dropdown
			if (isActionAttendueCandidat) {
				getCodesPaysEasa()
					.then((codesPays: CodePaysEasa[]) => {
						setCodesPaysEasa(codesPays)
					})
					.catch(() => {
						snackError(<FormattedMessage id="global.erreurs.generic" />)
					})
			}
		}

		return () => {
			resetMesPiecesJustificativesForExamenAction()
		}
	}, [examenSelectionne])

	/**
	 * Vérifie si les PJs obligatoires sont au complet.
	 */
	const isDossierPJsComplet = () => {
		return piecesJustificativesExamen
			.filter(pj => pj.obligatoire && pj.fichiers.length === 0)
			.length === 0
	}

	/**
	 * Permet l'envoi par le candidat de la PJ donnée.
	 */
	const submitPieceJustificative = (fichier: File,
	                                  idPj: number) => {
		uploadPieceJustificativeExamenCandidatAction(examenSelectionne.id, idPj, fichier)
			.then(() => {
				snackSuccess(
					<FormattedMessage id="inscriptions.piecesJustificativesDossier.messages.createPjSuccess"
					                  values={{ fileName: fichier.name }}
					/>
				)
			})
			.catch(() => {
				snackError(<FormattedMessage id="global.erreurs.generic" />)
			})
	}

	/**
	 * Permet la suppression par le candidat de la PJ donnée.
	 */
	const deletePieceJustificative = (idPj: number,
	                                  idFichierPj: number,
	                                  fileName: string) => {
		if (isActionAttendueCandidat) {
			deletePieceJustificativeExamenCandidatAction(examenSelectionne.id, idPj, idFichierPj)
				.then(() => {
					snackSuccess(
						<FormattedMessage id="inscriptions.piecesJustificativesDossier.messages.deletePjSuccess"
						                  values={{ fileName }}
						/>
					)
				})
				.catch(() => {
					snackError(<FormattedMessage id="global.erreurs.generic" />)
				})
		}
	}

	/**
	 * Marque le dossier comme étant à valider par un gestionnaire.
	 *
	 * @param form données de formulaire
	 */
	const submitForValidation = (form: FormValues) => {
		if (!isDossierPJsComplet()) {
			snackError(<FormattedMessage id="inscriptions.piecesJustificativesDossier.envoyer.dossierNonComplet" />)
		} else {
			sendMonDossierPJExamenCandidatForValidationAction(examenSelectionne.id, form)
				.then(() => {
					let noTelepilote = `${form[FIELD_NAME_TELEPILOTE_PREFIXE]}-RP-${form[FIELD_NAME_TELEPILOTE_SUFFIXE]}`

					// MàJ du n° de télépilote dans l'état civil du store
					updateMonNoTelepiloteAction(noTelepilote)

					// MàJ variable temporaire tant que la page n'est pas rechargée
					setTempNoTelepiloteAfterSubmit(noTelepilote)

					snackSuccess(
						<FormattedMessage id="inscriptions.piecesJustificativesDossier.messages.dossierValideSuccess" />
					)
				})
				.catch(() => {
					snackError(<FormattedMessage id="global.erreurs.generic" />)
				})
		}
	}

	const downloadPJ = (idPj: number, idFichier: number) => {
		checkMonFichierPJExamenCandidatExists(
			examenSelectionne.id,
			idPj,
			idFichier
		)
			.then((exists: boolean) => {
				if (exists) {
					download(
						`/files/me/examens/${examenSelectionne.id}/pieces-justificatives/${idPj}/${idFichier}`
					)
				} else {
					snackError(
						<FormattedMessage id="inscriptions.piecesJustificativesDossier.listePJ.fileNotExist" />
					)
				}
			})
			.catch(() => snackError(
				<FormattedMessage id="global.erreurs.generic" />
			))
	}

	/**
	 * Transforme un noTelepilote "NNN-RP-XXXXXXXXXXXX" au format "NNN - RP - XXXXXXXXXXXX".
	 *
	 * @param noTelepilote no télépilote
	 * @return no télépilote formaté pour l'affichage en lecture
	 */
	const getNoTelepiloteAffichage = (noTelepilote?: string) => (
		noTelepilote ? noTelepilote.replace('-RP-', ' - RP - ') : '-'
	)

	const renderPj = ({ fields }: { fields: any }): any => <React.Fragment>
		{piecesJustificativesExamen.map((pj, index: number) => {
				const fieldLibelle = `${pj.libelle}${pj.obligatoire ? ' *' : ''}`;
				return (
					<Grid key={index} container direction="row"
					      className={classes.row}
					>
						<Grid item md={10}>
							<div className={classes.pjTitleContainer}>
								<Typography className={classes.fakeFieldLabel}>{fieldLibelle}</Typography>
								{
									(
										examenSelectionne.statutDossier === EXAMEN_CANDIDAT_STATUT_DOSSIER.NON_CONFORME
										|| examenSelectionne.statutDossier === EXAMEN_CANDIDAT_STATUT_DOSSIER.VALIDE
									) && (
										<PieceJustificativeStatusBadge isPjValidee={pj.validee} />
									)
								}
							</div>

							<div className={classes.pjBadgeList}>
								{
									pj.fichiers.map(fichier => (
										<PieceJustificativeNameBadge
											fileName={fichier.libelle}
											canDelete={isActionAttendueCandidat && !pj.validee}
											handleClickBadge={() => downloadPJ(pj.id, fichier.id)}
											handleClickX={() => {
												deletePieceJustificative(pj.id, fichier.id, fichier.libelle)
											}}
										/>
									))
								}
							</div>

							{
								(isActionAttendueCandidat && !pj.validee) && (
									<InputFile
										name={`piecesJustificativesExamen[${index}].file`}
										id={`piecesJustificativesExamen[${index}].file`}
										key={pj.id}
										accept="image/jpg, image/png, image/gif, image/jpeg, application/pdf"
										onChange={($event: any) => submitPieceJustificative($event[0], pj.id)}
										noMargins
										hideNoFileChosenText
									/>
								)
							}
						</Grid>
					</Grid>
				)
			}
		)}
		{piecesJustificativesExamen.length === 0 && <EmptyResult propClasses={classes.emptyPj} />}
	</React.Fragment>

	return (
		<React.Fragment>
			{
				(
					examenSelectionne.messageStatutDossier
					&& examenSelectionne.statutDossier === EXAMEN_CANDIDAT_STATUT_DOSSIER.NON_CONFORME
				) && (
					<Error
						title={intl.formatMessage({ id: 'inscriptions.piecesJustificativesDossier.warningNonConforme.title' })}
						error={examenSelectionne.messageStatutDossier.split('\n')}
					/>
				)
			}

			<ResponsiveForm>
				<Title value={
					<FormattedMessage id="inscriptions.piecesJustificativesDossier.infosComplementaires.title" />
				} />

				{
					(
						isTelepiloteFieldDisabled
						|| !isActionAttendueCandidat
					) ?
						<InputDisabled
							label={intl.formatMessage({id: 'inscriptions.piecesJustificativesDossier.infosComplementaires.form.noTelepilote'})}
						>
							{
								getNoTelepiloteAffichage(tempNoTelepiloteAfterSubmit || etatCivil.numeroTelepilote)
							}
						</InputDisabled>
						:
						<>
							<Typography className={classes.fakeFieldLabel}>
								<FormattedMessage
									id="inscriptions.piecesJustificativesDossier.infosComplementaires.form.noTelepilote"
								/>
							</Typography>
							<Grid container className={classes.telepilote}>
								<Field
									id={FIELD_NAME_TELEPILOTE_PREFIXE}
									name={FIELD_NAME_TELEPILOTE_PREFIXE}
									component={SmallSelect}
									displayEmpty={true}

									disabled={isCATSCheckLoading}
								>
									{
										codesPaysEasa.map(value =>
											<MenuItem
												key={value.code}
												value={value.code}
											>
												{value.code}
											</MenuItem>
										)}
								</Field>
								<Typography className={classes.telepiloteRP}>-&nbsp;RP&nbsp;-</Typography>
								<Field id={FIELD_NAME_TELEPILOTE_SUFFIXE}
								       name={FIELD_NAME_TELEPILOTE_SUFFIXE}
								       component={Input}
								       inputStyle={classes.inputSuffixe}
								       disabled={isCATSCheckLoading}
								/>
							</Grid>
						</>
				}

				{
					isActionAttendueCandidat ?
					<Field
						id={FIELD_NAME_CERTIFICAT_COMPLEMENTAIRE}
						name={FIELD_NAME_CERTIFICAT_COMPLEMENTAIRE}
						label={<FormattedMessage
							id="inscriptions.piecesJustificativesDossier.infosComplementaires.form.isTitulaireBapd"
						/>}
						component={InputRadio}
						choices={[{
							label: <FormattedMessage id="global.btn.oui" />,
							value: `${true}`
						}, {
							label: <FormattedMessage id="global.btn.non" />,
							value: `${false}`
						}]}
						containerClass={(isTelepiloteFieldDisabled || !isActionAttendueCandidat) ? classes.marginTop : ''}
					/>
						: (
							<InputDisabled
								label={<FormattedMessage
									id="inscriptions.piecesJustificativesDossier.infosComplementaires.form.isTitulaireBapd"
								/>}
								className={classes.marginBot}
							>
								{
									examenSelectionne.certificatComplementaire ?
										<FormattedMessage id="global.btn.oui" />
										: <FormattedMessage id="global.btn.non" />
								}
							</InputDisabled>
						)
				}

				<Field id={FIELD_NAME_DATE_EXPIRATION_JUSTIFICATIF}
				       name={FIELD_NAME_DATE_EXPIRATION_JUSTIFICATIF}
				       label={<FormattedMessage
					       id={`inscriptions.piecesJustificativesDossier.infosComplementaires.form.${isTitulaireBapd ? 'dateExpirationCertifBapd' : 'dateExpirationCertifA1A3'}`}
				       />}
				       component={DateInput}
				       disabled={!isActionAttendueCandidat}
				       noMargin
				       type="date"
				/>

				<Title value={<FormattedMessage id={'inscriptions.piecesJustificativesDossier.listePJ.title'} />} />

				<BandeauInfo
					title={<FormattedHTMLMessage id="inscriptions.piecesJustificativesDossier.listePJ.warning.title" />}
					message={<FormattedHTMLMessage
						id="inscriptions.piecesJustificativesDossier.listePJ.warning.description" />}
				/>

				<Grid container direction="row">
					{
						isPJLoading ?
							<Loader />
							: (
								// @ts-ignore
								<FieldArray
									name={FIELD_NAME_PJ}
									component={renderPj}
									rerenderOnEveryChange
								/>
							)
					}
				</Grid>

				{
					isActionAttendueCandidat && (
						<Actions>
							<Button
								color="primary"
								variant="contained"
								loading={submitting}
								disabled={invalid}
								onClick={handleSubmit(submitForValidation)}
							>
								<FormattedMessage
									id="inscriptions.piecesJustificativesDossier.infosComplementaires.form.buttons.envoyer" />
							</Button>
						</Actions>
					)
				}
			</ResponsiveForm>
		</React.Fragment>
	)
}

const validate = (values: FormValues,
                  { listeInscriptions }: Partial<PiecesJustificativesExamenCandidatProps>): FormErrors<any> => {
	const errors: any = {}
	// RG6589/63626
	if (!values.numeroTelepiloteSuffixe || values.numeroTelepiloteSuffixe.length !== 12) {
		errors[FIELD_NAME_TELEPILOTE_SUFFIXE] = { id: 'inscriptions.piecesJustificativesDossier.infosComplementaires.form.erreurs.noTelepiloteSuffixeNbCaracteres' }
	}

	if (!values.numeroTelepilotePrefixe) {
		errors[FIELD_NAME_TELEPILOTE_PREFIXE] = { id: 'global.erreurs.obligatoire' }
	}

	// RG6610/63626
	// Erreur si le justificatif expire avant la dernière épreuve écran
	const dateExpirationJustificatif = (
		values[FIELD_NAME_DATE_EXPIRATION_JUSTIFICATIF] ?
			moment(values[FIELD_NAME_DATE_EXPIRATION_JUSTIFICATIF], DATE_FORMAT_YYYYMMDD_DASH)
			: undefined
	)
	if (!dateExpirationJustificatif || !dateExpirationJustificatif.isValid()) {
		errors[FIELD_NAME_DATE_EXPIRATION_JUSTIFICATIF] = { id: 'global.erreurs.obligatoire' }
	} else if (
		listeInscriptions
		&& listeInscriptions.filter(ins => {
			if (
				ins.typePassage === MODE_PASSAGE.ECRAN
				&& ins.etatInscription === INS_ETAT_INSCRIPTION.AFFECTEE
			) {
				const dateFin = ins.dateFin ? moment(ins.dateFin, DATE_FORMAT_MMDDYYYY_SLASH) : undefined
				return dateFin && dateExpirationJustificatif.isBefore(dateFin)
			} else {
				return false
			}
		}).length > 0
	) {
		errors[FIELD_NAME_DATE_EXPIRATION_JUSTIFICATIF] = { id: 'inscriptions.piecesJustificativesDossier.infosComplementaires.form.erreurs.certifExpireAvantDerniereEpreuve' }
	}
	return errors
}

const formSelector = formValueSelector(FORM_NAME)

const mapStateToProps = (state: any, ownProps: PiecesJustificativesExamenCandidatProps) => {
	let numeroTelepilotePrefixe = '';
	let numeroTelepiloteSuffixe = '';
	if (ownProps.etatCivil.numeroTelepilote) {
		numeroTelepilotePrefixe = ownProps.etatCivil.numeroTelepilote.split('-RP-')[0]
		numeroTelepiloteSuffixe = ownProps.etatCivil.numeroTelepilote.split('-RP-')[1]
	}

	return ({
		initialValues: {
			[FIELD_NAME_TELEPILOTE_PREFIXE]: numeroTelepilotePrefixe,
			[FIELD_NAME_TELEPILOTE_SUFFIXE]: numeroTelepiloteSuffixe,
			[FIELD_NAME_DATE_EXPIRATION_JUSTIFICATIF]: (
				ownProps.examenSelectionne.dateExpirationJustificatif ?
					formatDate(ownProps.examenSelectionne.dateExpirationJustificatif, DATETIME_FORMAT_SERVER_TIMESTAMP, DATE_FORMAT_YYYYMMDD_DASH)
					: undefined
			),
			[FIELD_NAME_CERTIFICAT_COMPLEMENTAIRE]: `${Boolean(ownProps.examenSelectionne.certificatComplementaire)}`
		},
		piecesJustificativesExamen: getListePiecesJustificativesExamenSelectionne(state),
		listeInscriptions: getExamenListeInscriptions(state),
		isTitulaireBapd: formSelector(state, FIELD_NAME_CERTIFICAT_COMPLEMENTAIRE) === 'true',
	})
}

export default compose(
	checkAuthorities(
		PROFIL_UTILISATEUR.CANDIDAT
	),
	injectIntl,
	injectMonProfil,
	injectMonSelectedExam,
	connect(mapStateToProps, actions),
	reduxForm({
		form: FORM_NAME,
		validate,
		enableReinitialize: true
	}),
	// @ts-ignore
	withStyles(styles),
	withTheme(),
	injectSnackActions,
	injectToolbarData(({ etatCivil, examenSelectionne, location }: any) => {
		return ({
			...getFilArianeExamen(
				etatCivil.id,
				etatCivil.numeroOceane,
				etatCivil.nomNaissance,
				etatCivil.prenomPrincipal,
				examenSelectionne.examenTypeLibelle,
				examenSelectionne.etat,
				false,
				!(location && location.search === '?payfip'),
				true
			),
			title: { id: 'inscriptions.title' }
		})
	})
)(PiecesJustificativesExamenCandidat)
