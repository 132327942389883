import { reducers as reducersIsotope } from 'isotope-client'
import { reducers } from '@oceane/ui'
import { examens } from '../modules/examens/services/examensReducers'
import { creerCompte } from '../modules/creerCompte/services/creationCompteReducers'
import { donneesExamen } from '../modules/donneesExamen/donneesExamenReducers'

export default {
	...reducersIsotope,
	...reducers({ examens }),
	creerCompte,
	donneesExamen
}
