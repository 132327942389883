import React from 'react'
import PropTypes from 'prop-types'
import MireConnexionWrapper from '../../../layouts/MireConnexionWrapper'
import GenererMotDePasseForm from './GenererMotDePasseForm'


const GenererMotDePassePage = () => (
	<MireConnexionWrapper>
		<GenererMotDePasseForm />
	</MireConnexionWrapper>
)


GenererMotDePassePage.propTypes = {
	classes: PropTypes.object
}

export default GenererMotDePassePage
