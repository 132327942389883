import withStyles from '@material-ui/core/styles/withStyles'
import { replace } from 'connected-react-router'
import { getStyles } from 'isotope-client'

import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import MireConnexionWrapper from '../../layouts/MireConnexionWrapper'
import CreationComptePopup from '../creerCompte/CreationComptePopup'
import LoginForm from './LoginForm'

const styles = theme => getStyles({
	root: {
		backgroundImage: theme.login.background,
		height: '100%',
		width: '100%',
		backgroundSize: 'cover',
		margin: 0
	},
	page: {
		marginTop: 12,
		marginBottom: 25
	}
})

const OceaneLoginPage = ({ location, user }) => {

	useEffect(() => {
		if (user.authenticated) {
			replace('/')
		}
	})


	return (
		<MireConnexionWrapper>
			<LoginForm nextPathname={location.state ? location.state.nextPathname : undefined} />
			<CreationComptePopup />
		</MireConnexionWrapper>
	)
}

OceaneLoginPage.propTypes = {
	location: PropTypes.object
}

const mapStateToProps = (state) => ({
	user: state.user
})

const mapDispatchToProps = (dispatch) => ({
	gotoDashboard: (location) => dispatch(replace({
		pathname: '/login',
		state: {
			nextPathname: location.pathname
		}
	}))
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)(OceaneLoginPage)
