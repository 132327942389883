import { createSelector } from 'reselect'

const getLocalState = state => state.creerCompte

export const getPopup = createSelector(
	getLocalState,
	state => state.popup
)

export const isPopupOpen = createSelector(
	getPopup,
	state => state.open
)

export const currentStep = createSelector(
	getPopup,
	state => state.step
)

export const showMessageCreationCompteSucces = createSelector(
	getLocalState,
	state => state.creationCompteSuccess
)
